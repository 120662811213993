import React from 'react'

import { Body2, CSSKeywords, Join, Link, Space, Strong } from '@gousto-internal/citrus-react'
import { connect } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { basketPromoCodeChange } from 'actions/basket'
import {
  promoApply,
  promoToggleModalVisibility,
  promoResetGoustoOnDemandFlow,
  promoClear,
} from 'actions/promos'
import { trackUTMAndPromoCode } from 'actions/tracking'
import { client } from 'config/routes'
import { getIsGoustoOnDemandEnabled } from 'selectors/features'

import { PromoModal } from './PromoModal'

const mapStateToProps = (state) => {
  const promoCode = state.promoCurrent
  const boxPrices = state.menuBoxPrices.getIn(['2', '2', 'gourmet'])
  const isGoustoOnDemandEnabled = getIsGoustoOnDemandEnabled(state)
  const isGoustoOnDemandError =
    boxPrices && !boxPrices.get('promoCodeValid') && isGoustoOnDemandEnabled
  const error = state.error.get(actionTypes.PROMO_GET) || state.error.get(actionTypes.PROMO_APPLY)
  const rawText = state.promoStore[promoCode]?.codeData?.campaign?.rawModalText || ''
  let title = state.promoStore[promoCode]?.codeData?.campaign?.modalTitle || null

  const productIds = (state.promoStore?.[promoCode]?.addGiftOrderRules || [])
    .filter((gift) => gift.type === 'Product')
    .map((gift) => gift.id)

  const numProducts = productIds.length

  const needsAgeVerification =
    productIds.map((id) => state.products.get(id)).filter((product) => product.get('ageRestricted'))
      .size > 0 && !state.user.get('ageVerified', false)

  let buttonText = error ? 'Close' : 'Claim discount'

  if (!title) {
    if (numProducts > 0) {
      if (numProducts > 1) {
        title = 'These are on us!'
      } else {
        title = "This one's on us!"
      }
      if (!error) {
        buttonText = 'Sounds Good'
      }
    } else {
      title = 'Join today with a tasty offer!'
    }
  }

  let modalTextContent

  if (rawText) {
    modalTextContent = (
      <Join with={<Space size={3} />}>
        {rawText.split('\n').map((text) => (
          <Body2 key={text}>{text}</Body2>
        ))}
      </Join>
    )
  } else if (numProducts === 0) {
    modalTextContent = (
      <Join with={<Space size={3} />}>
        <Body2>
          A voucher (<Strong>{promoCode}</Strong>) has been applied on your Gousto order!
        </Body2>
        <Body2>Your discount will be automatically applied to your account.</Body2>
        <Body2>Happy cooking!</Body2>
      </Join>
    )
  } else {
    modalTextContent = (
      <Body2>
        Here’s a discount for your first Gousto order. Your discount will be automatically applied
        at checkout.
      </Body2>
    )
  }

  if (error) {
    title = 'Sorry, your offer could not be applied!'
    buttonText = 'Close'
    modalTextContent =
      error === 'Code is only applicable for new customers' ? (
        <Body2>The promotion you tried to use is only for new customers.</Body2>
      ) : (
        <Join with={<Space size={3} />}>
          <Body2>Something went wrong and your offer could not be applied.</Body2>
          <Body2>
            Try again or contact our Customer Care team via our{' '}
            <Link
              href={client.helpCentre}
              target="_blank"
              rel="noopener noreferrer"
              size={CSSKeywords.Inherit}
            >
              Help Centre
            </Link>
            .
          </Body2>
        </Join>
      )
  }

  if (isGoustoOnDemandError) {
    title = 'Sorry, your offer could not be applied!'
    buttonText = 'Go to the homepage'
    modalTextContent = (
      <Join with={<Space size={3} />}>
        <Body2>Something went wrong and your offer could not be applied.</Body2>
        <Body2>Try again or contact the person / organisation who gave you the offer.</Body2>
      </Join>
    )
  }

  const isAgeVerified = state.promoAgeVerified || state.user.get('ageVerified', false)

  const pending = state.pending.get(actionTypes.PROMO_APPLY)

  return {
    error,
    modalTextContent,
    title,
    promoCode,
    needsAgeVerification,
    buttonText,
    isAgeVerified,
    pending,
    justApplied: state.promoStore?.[promoCode]?.justApplied || false,
    isGoustoOnDemandError,
    isGoustoOnDemandEnabled,
    percentageOff: boxPrices && boxPrices.get('percentageOff'),
  }
}

export const PromoModalContainer = connect(mapStateToProps, {
  promoApply,
  trackUTMAndPromoCode,
  closeModal: () => promoToggleModalVisibility(false),
  basketPromoCodeChange,
  promoResetGoustoOnDemandFlow,
  promoClear,
})(PromoModal)
