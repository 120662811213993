import { queueTrackingEvent } from '@library/tracking-integration'

type TrackingAction = 'display_earliest_delivery_date'

type LaterCutoffExperiment = 'cohort_a' | 'cohort_b' | 'cohort_c'

type TrackingDisplayEarliestDeliveryDate = {
  action: TrackingAction
  properties: {
    later_cutoff_experiment: LaterCutoffExperiment
    delivery_date: string
  }
}

// TG-8940: Support 2DD/10pm Cutoff Experiment
// We need to keep the tracking event after the 2DD/10pm Cutoff experiment
// but we need to make sure it does not fire later_cutoff_experiment property
export function trackDisplayEarliestDeliveryDate(
  laterCutoffExperiment: LaterCutoffExperiment,
  deliveryDate: string,
) {
  queueTrackingEvent<TrackingDisplayEarliestDeliveryDate>({
    action: 'display_earliest_delivery_date',
    properties: {
      later_cutoff_experiment: laterCutoffExperiment,
      delivery_date: deliveryDate,
    },
  })
}
