import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  FontFamily,
  FontWeight,
  Icon,
  IconVariant,
  JustifyContent,
  Text,
} from '@gousto-internal/citrus-react'

import { freeDeliveryPill } from '../boxPricesConfig'

export const FreeDeliveryPill = () => (
  <Box
    data-testid="free-delivery-pill"
    bg={Color.Success_50}
    width={['311px', '272px']}
    height="36px"
    display={Display.Flex}
    justifyContent={JustifyContent.Center}
    alignItems={AlignItems.Center}
    gap={2}
    style={{ borderRadius: '48px' }}
  >
    <Icon name="offer_pound" size={6} variant={IconVariant.Confirmation} />
    <Text
      color={Color.ColdGrey_800}
      fontFamily={FontFamily.Book}
      fontWeight={FontWeight.Normal}
      size={2}
    >
      {freeDeliveryPill.text}
    </Text>
  </Box>
)
