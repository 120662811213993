/**
 * Must run instrumentation first
 */
import './client/instrumentation'
import './config/publicPath'

import { browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import '@gousto-internal/storage-consent-web-package/dist/storageConsentBanner.css'

import { cookieBanner } from '@gousto-internal/storage-consent-web-package'
import { CLIENT_ID_COOKIE, getOptimizelySDK, getCookieForcedDecisions } from '@library/experimentation'
import { isDev } from '@library/environment/isomorphic'
import { getDatafile } from '@library/experimentation/browser'

import { routes } from 'routes'
import { AppContainer } from 'containers/AppContainer'
import transit from 'transit-immutable-js'
import Cookies from 'utils/GoustoCookies'
import { processCookies } from 'utils/processCookies'
import { processFeaturesQuery } from 'utils/processFeaturesQuery'
import { processQuery } from 'utils/processQuery'
import { loadFeatures } from 'utils/loadFeatures'
import { featuresLoadedFromStore } from 'utils/featuresLoadedFromStore'
import actions from 'actions'
import { docReady } from 'utils/docReady'
import { subscribeState } from 'utils/subscribeState'
import queryString from 'query-string'
import { clientAuthorise, refresh } from 'client/auth'
import { browserType } from 'utils/browserType'
import { getIsAuthenticated } from 'selectors/auth'
import { PublicStore } from 'client/publicStore'
import { configureStore } from './store'
import { initializePerformanceTrackerSender } from './performanceTracker/initializePerformanceTrackerSender'
import { cookieBannerActions, trackShowBanner } from './config/cookieBanner'

docReady('docReady', window)

let initialState = {}
try {
  initialState = transit.fromJSON(window.__initialState__)
} catch (error) {
  //
}

/**
 * Optimizely FX instantiation
 */
const forcedDecisions = getCookieForcedDecisions(Cookies)
const optimizelyFX = getOptimizelySDK(
  getDatafile(),
  Cookies.get(CLIENT_ID_COOKIE),
  { forcedDecisions }
)

const store = configureStore({
  cookies: Cookies,
  history: browserHistory,
  initialState,
  optimizelyFX
})

processCookies(Cookies, store)
store.dispatch(actions.setUTMSource())

const history = syncHistoryWithStore(browserHistory, store)

window.docReady(() => {
  const consentCookie = Cookies.get('v1_gousto_cookie_consent')

  /**
   * The cookie banner is only shown if the consent cookie is not set
   * We test for the absence of the consent cookie to track the show of the banner
   */
  if (!consentCookie) {
    trackShowBanner()
  }
  cookieBanner.init(cookieBannerActions)

  clientAuthorise(store)
  const query = queryString.parse(window.location.search)
  processFeaturesQuery(query, store)
  processQuery(query, store, {hashTag: window.location.hash})

  refresh(store)

  browserType(store)

  initializePerformanceTrackerSender(store)

  subscribeState(store)

  const reactRootDOM = document.getElementById('react-root')

  if (reactRootDOM && !(initialState.serverError && initialState.serverError === '500')) {
    ReactDOM.render(
      <AppContainer
        history={history}
        routes={routes(store)}
        store={store}
        optimizelyFX={optimizelyFX}
      />,
      reactRootDOM
    )

    window.clientRenderStarted = true
  } else {
    console.log(new Error('reactRootDOM not found')) // eslint-disable-line no-console
  }
})

window.onhashchange = () => {
  const isOfLoginHashTag = window.location.hash.indexOf('login') !== -1
  const isNotAuthenticated = !getIsAuthenticated(store.getState())
  if (isOfLoginHashTag && isNotAuthenticated) {
    store.dispatch(actions.loginVisibilityChange(true))
  }
}

/* eslint-disable no-underscore-dangle */
if (!isDev()) {
  // Sanitised store that only exposes a subset of data
  window.__store__ = new PublicStore(store)
} else {
  window.__store__ = store
}
/* eslint-enable */

window.__loadFeatures__ = features => loadFeatures(features, store) // eslint-disable-line no-underscore-dangle

window.__featuresLoadedFromStore__ = features => featuresLoadedFromStore(features, store) // eslint-disable-line no-underscore-dangle
