import { createSelector } from 'reselect'

import { actionTypes } from 'actions/actionTypes'
import { getAvailableNumPortions } from 'selectors/basket'

import { boxSizes as boxSize } from './boxPricesConfig'
import { State } from './boxPricesModels'

export const getError = (state: State) => state.error.get(actionTypes.MENU_BOX_PRICES_RECEIVE)

export const getMenuBoxPrices = (state: State) => state.menuBoxPrices

export const getMenuBoxPricesReceive = (state: State) =>
  state.pending.get(actionTypes.MENU_BOX_PRICES_RECEIVE)

const BOX_TYPE = 'gourmet'

export const getNumPersonsToBoxDescriptors = createSelector(
  getMenuBoxPrices,
  getAvailableNumPortions,
  (menuBoxPrices, availableBoxSizes) => {
    if (!menuBoxPrices) {
      return null
    }
    const numPersonsResult = availableBoxSizes?.map(String)

    const boxSizeInfo = numPersonsResult?.reduce((acc: Record<string, unknown>, key: string) => {
      if (boxSize[key]) {
        acc[key] = boxSize[key]
      }

      return acc
    }, {})

    const result = numPersonsResult
      ?.map((numPersons: string) => {
        const boxDescriptors = boxSizeInfo[numPersons].map((numPortionsStr: string) => ({
          num_portions: Number(numPortionsStr),
          price_per_portion: menuBoxPrices.getIn([
            numPersons,
            numPortionsStr,
            BOX_TYPE,
            'pricePerPortion',
          ]),
          price_per_portion_discounted: menuBoxPrices.getIn([
            numPersons,
            numPortionsStr,
            BOX_TYPE,
            'pricePerPortionDiscounted',
          ]),
          promo_code_valid: menuBoxPrices.getIn([
            numPersons,
            numPortionsStr,
            BOX_TYPE,
            'promoCodeValid',
          ]),
          total: menuBoxPrices.getIn([numPersons, numPortionsStr, BOX_TYPE, 'recipeTotal']),
          totalAfterDiscount: menuBoxPrices.getIn([numPersons, numPortionsStr, BOX_TYPE, 'total']),
        }))

        return [numPersons, boxDescriptors]
      })
      .reduce(
        (
          numPersonsToBoxDescriptors: Record<string, string>,
          [numPersons, boxDescriptors]: [string, string],
        ) => ({
          ...numPersonsToBoxDescriptors,
          [numPersons]: boxDescriptors,
        }),
        {},
      )

    return result
  },
)

export const getPricePerServing = createSelector(getMenuBoxPrices, (menuBoxPrices) => {
  if (!menuBoxPrices) {
    return null
  }

  return menuBoxPrices.getIn(['4', '4', 'gourmet', 'pricePerPortion'])
})
