import { NumPersonsToBoxDescriptorsList } from '../boxPricesModels'

export enum BoxComparisonHeaderType {
  SELECT_MORE_RECIPES = 'selectMoreRecipes',
  BEST_PRICE = 'bestPrice',
}

export type BoxPriceSuitableForSectionProps = {
  numPersons: number
}

export type BoxPriceBlockProps = {
  boxInfo: NumPersonsToBoxDescriptorsList
  numPersons: number
  isSelected: boolean
}

export type BoxComparisonTableProps = {
  pricePerPortion: string
  pricePerBox: string
  boxSize: number
  numPortions: number
}

export type BoxComparisonTableHeaderProps = {
  type: BoxComparisonHeaderType
  headerValue: string
}
