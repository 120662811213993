import React, { ChangeEvent } from 'react'

import { RadioGroup } from '@gousto-internal/citrus-react'

const options = [
  { value: '1', label: 'Weekly' },
  { value: '2', label: 'Fortnightly' },
  { value: '4', label: 'Monthly' },
]

export const FrequencyOptions = ({
  selectedFrequency,
  onChange,
}: {
  selectedFrequency: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}) => <RadioGroup value={selectedFrequency} onChange={onChange} outline options={options} />
