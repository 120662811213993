import { fetchRafCampaignDetails } from '@library/api-promocode'
import { canUseWindow } from '@library/environment'
import { useEffect, useState } from 'react'

export type RafCampaignDetails = {
  firstBoxDiscount: string
  secondBoxDiscount: string
  giftYourFriendCredit: string
}
export type RafData = Record<string, string>

export const useFetchRafCampaignDetails = (): RafCampaignDetails => {
  const [rafData, setRafData] = useState<RafData>({})

  const getRafData = async () => {
    if (!canUseWindow()) return

    const promoCode = new URLSearchParams(window.location.search).get('promo_code')
    const response = await fetchRafCampaignDetails(promoCode || 'default')
    setRafData(response as unknown as RafData)
  }

  useEffect(() => {
    getRafData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    firstBoxDiscount: rafData?.firstBoxDiscountFormatted?.replace('%', '') || '--',
    secondBoxDiscount: rafData?.firstMonthDiscountFormatted?.replace('%', '') || '--',
    giftYourFriendCredit: rafData?.creditFormatted || '--',
  }
}
