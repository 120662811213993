import React, { useMemo } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { generateHref } from 'Helmet/GoustoHelmet'
import Helmet from 'react-helmet'
import { useDispatch } from 'react-redux'
import useSWR from 'swr'

import { Homepage } from '@features/homepage'
import { Recipe } from '@features/homepage/src/types/recipeType'
import { menuFetcherV3 } from '@library/menu-service'
import { Menu } from '@library/menu-service/queries/menuApiMenu/types'

import { homeGetStarted } from 'actions/home'
import { RibbonTriggerContainer } from 'components/RibbonTrigger'
import routesConfig from 'config/routes'
import { getSessionId } from 'selectors/cookies'

import { useGetCtaProps } from './Cta/ctaHooks'
import {
  useCanShowPromoBanner,
  useGetPromoBannerDispatchedActions,
} from './PromoBanner/promoBannerHooks'
import { knownVariants, defaultVariant, seo } from './homeConfig'
import { useGetMenuData, usePromocodeData } from './homeHooks'

const formatRecipes = (response?: Menu): Record<string, Recipe> => {
  if (!response || !response.recipes) {
    return {}
  }

  const recipes = Object.keys(response.recipes).reduce((acc, recipeId) => {
    const {
      id,
      dietary_claims: dietaryClaims,
      images,
      name,
      prep_time: prepTime,
      rating,
    } = response.recipes[recipeId]

    return {
      ...acc,
      [id]: {
        id,
        dietaryClaims: dietaryClaims.map((dietaryClaim) => ({
          name: dietaryClaim.name,
          slug: dietaryClaim.slug,
        })),
        title: name,
        prepTimes: {
          for2: prepTime,
        },
        rating: {
          count: rating.count,
          average: rating.average,
        },
        media: {
          images: images.map((image) => ({
            urls: image.crops.map((crop) => ({ src: crop.url })),
          })),
        },
      },
    }
  }, {})

  return recipes
}

const getHelmetLink = (variant: string) => {
  const {
    client: { home },
  } = routesConfig
  const knownVariant = knownVariants.includes(variant) ? variant : defaultVariant
  const link = knownVariant !== 'default' ? [{ rel: 'canonical', href: generateHref(home) }] : []

  return link
}

type HomePageProps = { location: { query: { variant: string } } }

export const HomePageContent = ({ location }: HomePageProps) => {
  const dispatch = useDispatch()
  const sessionId = getSessionId()

  const { data: menuResponse } = useSWR(
    {
      numPortions: 2,
      sessionId,
    },
    menuFetcherV3,
  )

  const { dispatchRedirectLoggedInUser } = useGetMenuData()

  dispatchRedirectLoggedInUser()

  const link = useMemo(() => getHelmetLink(location.query.variant), [location.query.variant])
  const { dispatchApplyPromoCodeAndShowModal, dispatchRedirect, dispatchTrackUTMAndPromoCode } =
    useGetPromoBannerDispatchedActions()

  const canShowPromoBanner = useCanShowPromoBanner()
  const applyDiscount = () => {
    dispatchApplyPromoCodeAndShowModal()
    dispatchRedirect()
    dispatchTrackUTMAndPromoCode()
  }

  const { ctaText, ctaUri } = useGetCtaProps()
  const { defaultBannerText } = usePromocodeData()

  const handleSignupCTAClick = () => {
    datadogRum.addAction('start_onboarding')
    dispatch(homeGetStarted(ctaUri))
  }

  const recipes = useMemo(() => formatRecipes(menuResponse), [menuResponse])

  return (
    <>
      <Helmet
        title={seo.title}
        meta={seo.meta}
        link={link}
        style={[
          {
            cssText: `
              #react-root {
                height: 100%;
              }
            `,
          },
        ]}
      />

      <Homepage
        applyDiscount={applyDiscount}
        isPromoBannerVisible={canShowPromoBanner}
        promoBannerText={defaultBannerText}
        signupCTA={ctaText}
        onSignupCTAClick={handleSignupCTAClick}
        recipes={recipes}
      />

      <RibbonTriggerContainer name="home-page" />
    </>
  )
}
