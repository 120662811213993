import React from 'react'

import { ModalProvider, ThemeProvider } from '@gousto-internal/citrus-react'
import { OptimizelyProvider } from '@optimizely/react-sdk'
import { ApplePayStatusFetch } from 'ApplePayStatusFetch/ApplePayStatusFetch'
import Cookies from 'cookies-js'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Router, applyRouterMiddleware } from 'react-router'
import { useScroll as setScroll } from 'react-router-scroll'
import { SWRConfig } from 'swr'

import { ForcedDecisionsOverlay } from '@library/experimentation'
import { FontConfig } from '@library/font-config'
import { getStaticAssetURL } from '@library/static-assets'

import { RibbonIsAuthenticatedAttributeReporter } from 'components/RibbonTrigger'
import { SetupOptimizelyOverride } from 'containers/OptimizelyRollouts'
import { hashLinkScroll } from 'routes/hashLinkScroll'
import { shouldScroll } from 'routes/shouldScroll'
import { trackPageChange } from 'routes/trackPageChange'
import { canUseWindow } from 'utils/browserEnvironment'
import { executeVirtualView } from 'utils/usabilla'

export const AppContainer = ({ history, optimizelyFX, routes, store }) => (
  <Provider store={store}>
    <OptimizelyProvider optimizely={optimizelyFX}>
      <FontConfig fontsPath={getStaticAssetURL('/fonts')} />
      <SetupOptimizelyOverride />
      <ApplePayStatusFetch />
      <SWRConfig
        value={{
          revalidateOnFocus: false,
        }}
      >
        <ThemeProvider>
          <ModalProvider>
            <Router
              history={history}
              render={canUseWindow() ? applyRouterMiddleware(setScroll(shouldScroll)) : undefined}
              onUpdate={() => {
                trackPageChange(store)
                hashLinkScroll()
                executeVirtualView()
              }}
            >
              {routes}
            </Router>
            <RibbonIsAuthenticatedAttributeReporter />
            <ForcedDecisionsOverlay cookiesSource={Cookies} />
          </ModalProvider>
        </ThemeProvider>
      </SWRConfig>
    </OptimizelyProvider>
  </Provider>
)

AppContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  optimizelyFX: PropTypes.object.isRequired,
  routes: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  store: PropTypes.object.isRequired,
}
