import React from 'react'

import {
  Body1,
  Box,
  Heading4,
  Join,
  Modal,
  ModalBody,
  ModalClose,
  ModalFooter,
  ModalHeader,
  Space,
  useTheme,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { useSelector } from 'react-redux'

import { MenuRecipe, MenuRecipeImage } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { SectionPanel } from 'routes/Menu/components/Detail/SectionPanel'
import { getNumPortions } from 'selectors/basket'
import { getBrowserType } from 'selectors/browser'
import { getFeaturedImage, getRangeImages } from 'utils/image'

import { RecipeSummaryGrid, RecipeDetailsGrid } from './AttributeGrid'
import { DetailCarousel } from './Carousel'
import { DetailAddRecipe } from './DetailAddRecipe'
import { DetailAllergenIngredients } from './DetailAllergenIngredients'
import { DetailError } from './DetailError'
import { DetailImage } from './DetailImage'
import { DetailIngredients } from './DetailIngredients'
import { DetailPerPortion } from './DetailPerPortion'
import { RecipeDetailsPageOptions } from './Options/RecipeDetailsPageOptions'
import { Title } from './Title'
import { DetailViewType } from './types'

import css from './Detail.css'

type DetailProps = {
  id: string
  title: string
  showOverlay: boolean
  error: boolean
  loading: boolean
  recipe: MenuRecipe
  reload: () => void
  onClose: () => void
  position?: string
}

type RenderRecipeImageProps = {
  browserType: string
  images: MenuRecipeImage[]
  isBlueyRecipe: boolean
  isFineDineIn: boolean
  title: string
  view: DetailViewType
}

const LoadingSkeleton = ({ style }: { style?: React.CSSProperties }) => (
  <span className={css.loadingSkeleton} style={style} data-testid="LoadingSkeleton" />
)

const ImageContainer = withCitrusTheme(Box, (theme) => ({
  marginLeft: `-${theme.spacing(4)}px`,
  marginTop: `-${theme.spacing(4)}px`,
  width: `calc(100% + 2*${theme.spacing(4)}px)`,
  [`@media (min-width: ${theme.tokens.breakpoints[0]})`]: {
    marginLeft: `-${theme.spacing(6)}px`,
    marginTop: `-${theme.spacing(6)}px`,
    width: `calc(100% + 2*${theme.spacing(6)}px)`,
  },
}))

const renderRecipeImage = ({
  browserType,
  images,
  isBlueyRecipe,
  isFineDineIn,
  title,
  view,
}: RenderRecipeImageProps) => {
  const featuredImage = getFeaturedImage(images, 'detail', browserType)

  if (!isBlueyRecipe && !isFineDineIn) {
    return <DetailImage media={featuredImage} title={title} view={view} />
  }

  const rangeImages = getRangeImages(images)

  return (
    <DetailCarousel
      isBlueyRecipe={isBlueyRecipe}
      images={rangeImages}
      fallbackImage={featuredImage}
    />
  )
}

export const Detail = ({
  id,
  title,
  showOverlay,
  error,
  loading,
  recipe,
  reload,
  onClose,
  position = '0',
}: DetailProps) => {
  const { theme } = useTheme()
  const browserType = useSelector(getBrowserType)

  const numPortions = useSelector(getNumPortions)

  const {
    basics,
    description,
    food_brand: foodBrand,
    images,
    is_chef_prepared: isChefPrepared,
    surcharge,
    options,
    id: recipeUuid,
  } = recipe

  const isBlueyRecipe = foodBrand?.slug === 'bluey'
  const isFineDineIn = foodBrand?.slug === 'fine-dine-in'
  const view = isBlueyRecipe || isFineDineIn ? 'fineDineInDetail' : 'detail'

  return (
    <Modal
      name="recipe-detail-modal"
      isOpen={showOverlay}
      onRequestClose={onClose}
      overlayClassName={css.overlay}
    >
      <ModalClose onClose={onClose} />
      <ModalHeader>
        <Title data-testing="recipeDetailTitle">{title}</Title>
      </ModalHeader>
      <ModalBody>
        <Box>
          <ImageContainer>
            {renderRecipeImage({
              browserType,
              isBlueyRecipe,
              isFineDineIn,
              images,
              title,
              view,
            })}
          </ImageContainer>
          <SectionPanel>
            <Box style={{ marginBottom: theme.spacing(4) }}>
              <RecipeDetailsPageOptions recipeUuid={recipeUuid} options={options} />
            </Box>
            <RecipeSummaryGrid recipe={recipe} loading={loading} />
            <Join with={<Space size={2} />}>
              <Box data-testing="recipeDetailsDescription" paddingV={2}>
                {loading ? (
                  <LoadingSkeleton style={{ height: '125px', width: '95%' }} />
                ) : (
                  <Body1>{description}</Body1>
                )}
              </Box>
              <Heading4>Recipe details</Heading4>
              <RecipeDetailsGrid recipe={recipe} loading={loading} />
              {loading && (
                <>
                  <LoadingSkeleton style={{ height: '10px', width: '50%', marginBottom: '15px' }} />
                  <LoadingSkeleton style={{ height: '10px', width: '55%' }} />
                </>
              )}
            </Join>
          </SectionPanel>
          {error ? (
            <DetailError reload={reload} />
          ) : (
            <>
              <DetailIngredients loading={loading} recipe={recipe} basics={basics} />
              <DetailPerPortion loading={loading} recipe={recipe} numPortions={numPortions} />
              <DetailAllergenIngredients recipe={recipe} loading={loading} />
            </>
          )}
        </Box>
      </ModalBody>
      <ModalFooter>
        <DetailAddRecipe
          id={id}
          view={view}
          surcharge={surcharge?.price_per_portion}
          position={position}
          isChefPrepared={isChefPrepared}
        />
      </ModalFooter>
    </Modal>
  )
}
