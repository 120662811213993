import React from 'react'

import {
  Color,
  BorderStyle,
  Box,
  ResponsiveValue,
  FontFamily,
  Space,
  Text,
  TextAlign,
  Display,
  AlignItems,
  FontWeight,
} from '@gousto-internal/citrus-react'

import { getPromotionalPricing } from '../helpers/discount/discounts'
import { useIsBoxPricesDiscountVisibilityEnabled } from '../hooks/useIsBoxPricesDiscountVisibilityEnabled'
import { BoxComparisonTableHeaderProps, BoxComparisonTableProps } from './boxPriceBlockModels'

const colorSchemes = {
  selectMoreRecipes: {
    boxType: Box,
    bgColor: Color.ColdGrey_700,
    color: Color.White,
    testingId: 'more-recipes',
    borderColor: Color.ColdGrey_100,
    fontWeight: FontWeight.Bold,
  },
  bestPrice: {
    boxType: Box,
    bgColor: Color.Success_800,
    color: Color.White,
    testingId: 'best-price',
    borderColor: Color.ColdGrey_200,
    fontWeight: FontWeight.Bold,
  },
}

export const BoxComparisonTableHeader = ({ type, headerValue }: BoxComparisonTableHeaderProps) => {
  const {
    boxType: HeaderBox,
    color,
    bgColor,
    borderColor,
    testingId,
    fontWeight,
  } = colorSchemes[type]

  return (
    <HeaderBox
      bg={bgColor as ResponsiveValue<Color>}
      borderStyle={BorderStyle.Solid}
      borderColor={borderColor}
      borderWidth={0.5}
      color={color}
      paddingV={1}
      data-testid={testingId}
    >
      <Text textAlign={TextAlign.Center} size={1} fontWeight={fontWeight}>
        {headerValue}
      </Text>
    </HeaderBox>
  )
}

export const BoxComparisonTable = ({
  pricePerPortion,
  pricePerBox,
  boxSize,
  numPortions,
}: BoxComparisonTableProps) => {
  const isBoxPricesDiscountVisibilityEnabled = useIsBoxPricesDiscountVisibilityEnabled()
  const promotionalPricing = getPromotionalPricing(boxSize, numPortions)
  const {
    firstBoxPriceWithDiscount: firstBoxDiscountedPrice,
    firstBoxPerPortionPriceWithDiscount: discountedPricePerPortion,
    totalSaving: totalPromotionalSavings,
  } = promotionalPricing

  return (
    <>
      {isBoxPricesDiscountVisibilityEnabled ? (
        <Box width="100%" display={Display.InlineFlex}>
          <Box
            width="50%"
            bg={Color.NeutralGrey_50}
            borderStyle={BorderStyle.Solid}
            borderColor={Color.ColdGrey_200}
            borderWidth={0.5}
            borderTopWidth={0}
            alignItems={AlignItems.Center}
          >
            <Space size={2} />
            <Text
              size={7}
              textAlign={TextAlign.Center}
              color={Color.Success_800}
              fontWeight={FontWeight.Bold}
            >
              £{firstBoxDiscountedPrice}{' '}
              <Text size={2} textAlign={TextAlign.Center} display={Display.Inline}>
                per order
              </Text>
            </Text>
            <Space size={1} />
            <Text size={2} textAlign={TextAlign.Center} color={Color.ColdGrey_600}>
              Usually £{pricePerBox} per order
            </Text>

            <Space size={2} />
          </Box>
          <Box
            width="50%"
            borderStyle={BorderStyle.Solid}
            borderColor={Color.ColdGrey_200}
            borderRightWidth={0.5}
            borderBottomWidth={0.5}
            alignItems={AlignItems.Center}
          >
            <Space size={2} />
            <Text
              size={7}
              textAlign={TextAlign.Center}
              color={Color.Success_800}
              fontWeight={FontWeight.Bold}
            >
              £{discountedPricePerPortion}{' '}
              <Text size={2} textAlign={TextAlign.Center} display={Display.Inline}>
                per portion
              </Text>
            </Text>
            <Space size={1} />
            <Text size={2} textAlign={TextAlign.Center} color={Color.ColdGrey_600}>
              Usually £{pricePerPortion} per portion
            </Text>
            <Space size={2} />
          </Box>
        </Box>
      ) : (
        <Box width="100%" display={Display.InlineFlex}>
          <Box
            width="50%"
            bg={Color.NeutralGrey_50}
            borderStyle={BorderStyle.Solid}
            borderColor={Color.ColdGrey_200}
            borderWidth={0.5}
            borderTopWidth={0}
            alignItems={AlignItems.Center}
          >
            <Space size={2} />
            <Text size={4} textAlign={TextAlign.Center} fontWeight={FontWeight.Bold}>
              £{pricePerBox}
            </Text>

            <Text size={3} fontFamily={FontFamily.Book} textAlign={TextAlign.Center}>
              per order
            </Text>

            <Space size={2} />
          </Box>
          <Box
            width="50%"
            borderStyle={BorderStyle.Solid}
            borderColor={Color.ColdGrey_200}
            borderRightWidth={0.5}
            borderBottomWidth={0.5}
            alignItems={AlignItems.Center}
          >
            <Space size={2} />
            <Text
              size={4}
              textAlign={TextAlign.Center}
              color={Color.Success_800}
              fontWeight={FontWeight.Bold}
            >
              £{pricePerPortion}
            </Text>
            <Text size={3} textAlign={TextAlign.Center} color={Color.Success_800}>
              per portion
            </Text>
            <Space size={2} />
          </Box>
        </Box>
      )}
      <Space size={4} />
      {isBoxPricesDiscountVisibilityEnabled && (
        <Text
          data-testid="total-promotional-savings"
          size={4}
          fontWeight={FontWeight.Bold}
          textAlign={TextAlign.Center}
        >
          Save up to{' '}
          <Text
            color={Color.Success_800}
            size={4}
            fontWeight={FontWeight.Bold}
            textAlign={TextAlign.Center}
            display={Display.Inline}
          >
            £{totalPromotionalSavings}
          </Text>{' '}
          in your first 2 months
        </Text>
      )}
    </>
  )
}
