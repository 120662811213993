import React, { memo, useEffect, useRef, useState } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import {
  AlignItems,
  Body1,
  Box,
  ButtonColorVariant,
  Checkbox,
  Color,
  Display,
  FlexDirection,
  FontFamily,
  Heading5,
  InputField,
  JustifyContent,
  Link,
  Modal,
  ModalBody,
  ModalButton,
  ModalClose,
  ModalFooter,
  ModalHeader,
  Space,
  Text,
  useModal,
} from '@gousto-internal/citrus-react'
import { Recaptcha } from 'Recaptcha'
import { NullableReCAPTCHA } from 'Recaptcha/useChallengeClosingObserver'
import { Spinner } from 'goustouicomponents'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import login from 'actions/login'
import routesConfig from 'config/routes'
import { getIsAuthenticating } from 'selectors/auth'
import { getUserLoginError } from 'selectors/status'

import {
  EMAIL_VALIDATION_RULES,
  LOGIN_MODAL_COPY,
  PASSWORD_VALIDATION_RULES,
  EXISTING_EMAIL_LOGIN_MODAL_NAME,
} from './config'

export const ExistingEmailLoginModal = memo(() => {
  const dispatch = useDispatch()
  const { closeCurrentModal } = useModal()

  const statusError = useSelector(getUserLoginError)
  const isAuthenticating = useSelector(getIsAuthenticating)
  const [rememberMe, setRememberMe] = useState(true)
  const [isLoginProcessing, setIsLoginProcessing] = useState(false)

  const recaptchaRef = useRef<NullableReCAPTCHA>(null)
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onBlur',
  })

  const emailValue = watch('email')
  const passwordValue = watch('password')

  const handleClose = () => {
    reset({
      email: '',
      password: '',
    })
    closeCurrentModal()
  }

  const processLogin = handleSubmit((data) => {
    if (!data.email || !data.password) return
    setIsLoginProcessing(true)
    datadogRum.addAction('start_existing_email_login')
    dispatch(
      login.loginUser({
        email: data.email,
        password: data.password,
        rememberMe,
        // @ts-expect-error Incorrect inferred type for loginUser
        recaptchaToken: recaptchaValue,
      }),
    )
  })

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked)
  }

  const handleClick = () => {
    if (recaptchaRef?.current && recaptchaValue === null) {
      recaptchaRef.current.execute()
    } else {
      processLogin()
    }
  }

  const captchaChanges = (token: string | null) => {
    setRecaptchaValue(token)
  }

  useEffect(() => {
    if (statusError && recaptchaRef.current) {
      setRecaptchaValue(null)
      setIsLoginProcessing(false)
      recaptchaRef.current?.reset()
    }
  }, [statusError])

  if (recaptchaValue !== null && !isLoginProcessing) {
    setIsLoginProcessing(true)
    processLogin()
  }

  const isCTADisabled = Boolean(errors.email || !emailValue || errors.password || !passwordValue)

  return (
    <Modal data-testid="existingEmailLoginModal" name={EXISTING_EMAIL_LOGIN_MODAL_NAME}>
      <ModalClose onClose={handleClose} />
      <ModalHeader>
        <Heading5>{LOGIN_MODAL_COPY.loginCopy}</Heading5>
      </ModalHeader>
      <form data-testid="existingEmailLoginModalForm">
        <ModalBody>
          <InputField
            data-testid="emailInput"
            id="email"
            type="email"
            label={LOGIN_MODAL_COPY.emailLabel}
            // eslint-disable-next-line
            {...register('email', EMAIL_VALIDATION_RULES)}
            validationMessage={errors.email?.message}
          />
          <Space size={2} />
          <InputField
            id="password"
            type="password"
            data-testid="passwordInput"
            label={LOGIN_MODAL_COPY.passwordLabel}
            // eslint-disable-next-line
            {...register('password', PASSWORD_VALIDATION_RULES)}
            validationMessage={errors.password?.message}
          />
          <Space size={2} />
          <Box
            justifyContent={JustifyContent.SpaceBetween}
            alignItems={AlignItems.Center}
            display={Display.Flex}
            flexDirection={FlexDirection.Row}
          >
            <Box>
              <Checkbox onChange={handleCheckboxChange} checked={rememberMe} id="checkbox">
                <Body1 fontFamily={FontFamily.Bold} color={Color.Secondary_600}>
                  {LOGIN_MODAL_COPY.rememberMe}
                </Body1>
              </Checkbox>
            </Box>
            <Box display={Display.Flex} flexDirection={FlexDirection.Row}>
              <Link href={routesConfig.client.resetForm}>
                <Body1 color={Color.Secondary_600} fontFamily={FontFamily.Bold}>
                  {LOGIN_MODAL_COPY.forgotPassword}
                </Body1>
              </Link>
            </Box>
          </Box>
          <Box paddingH={2}>
            {statusError && (
              <Text size={0} color={Color.Error_900}>
                {statusError}
              </Text>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            data-testid="modalLoginCTA"
            disabled={isCTADisabled}
            height="48px"
            colorVariant={ButtonColorVariant.Primary}
            onClick={handleClick}
          >
            {isAuthenticating ? <Spinner /> : LOGIN_MODAL_COPY.loginCopy}
          </ModalButton>
        </ModalFooter>
        <Recaptcha onChange={captchaChanges} ref={recaptchaRef} />
      </form>
    </Modal>
  )
})

ExistingEmailLoginModal.displayName = 'ExistingEmailLoginModal'
