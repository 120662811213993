import { useCallback, useEffect, useState } from 'react'

import { fetchDeliveryDays } from 'apis/deliveries'

type DaySlotLeadTime = {
  dayId: string
  slotId: string
  leadTime: number
  defaultDay: number
  date: string
  isSlotDefault: boolean
  activeForSubscribersOneOff: boolean
}

export type DeliveryDay = {
  id: string
  daySlotLeadTimes: DaySlotLeadTime[]
  date: string
}

type Props = {
  slotId: string
  accessToken: string
  cutOffDateTimeFrom: string
  cutOffDateTimeUntil: string
  deliveryTariffId: string
  postcode: string
}

export type DeliveryDays = {
  data: DeliveryDay[] | null
  deliveryDate: string | null
}

/**
 * Loading, Error State, Delivery Day
 */
type UseSubscriptionToDeliveryDays = [boolean, Error | null, DeliveryDays]

export function useSubscriptionToDeliveryDays({
  slotId,
  accessToken,
  cutOffDateTimeFrom,
  cutOffDateTimeUntil,
  deliveryTariffId,
  postcode,
}: Props): UseSubscriptionToDeliveryDays {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const [returnedDeliveryDay, setDeliveryDay] = useState<DeliveryDays>({
    data: null,
    deliveryDate: null,
  })

  const tryFetchDays = useCallback(async () => {
    try {
      const response = await fetchDeliveryDays(
        accessToken,
        cutOffDateTimeFrom,
        cutOffDateTimeUntil,
        deliveryTariffId,
        postcode,
      )

      const data = response.data as DeliveryDay[]

      const defaultDeliveryDays = data.map((day) => ({
        ...day,
        ...day.daySlotLeadTimes.filter((dslt) => dslt.isSlotDefault === true)[0],
      }))

      const deliverySlotMatchedWithSubscriptionSlotId = defaultDeliveryDays
        .filter((dslt) => dslt.slotId === slotId && dslt.activeForSubscribersOneOff)
        .sort((a, b) => a.leadTime - b.leadTime)

      setDeliveryDay({
        data: defaultDeliveryDays,
        deliveryDate: deliverySlotMatchedWithSubscriptionSlotId[0]?.date ?? null,
      })
    } catch (err: unknown) {
      setError(err as Error)
    } finally {
      setLoading(false)
    }
  }, [accessToken, cutOffDateTimeFrom, cutOffDateTimeUntil, deliveryTariffId, postcode, slotId])

  useEffect(() => {
    tryFetchDays()
  }, [tryFetchDays])

  return [loading, error, returnedDeliveryDay]
}
