import React from 'react'

import {
  AlignItems,
  BorderStyle,
  Box,
  Color,
  Display,
  FlexDirection,
  Space,
} from '@gousto-internal/citrus-react'

import { Cta } from './Cta'
import { DiscountPerWeek } from './DiscountPerWeek'
import { FreeDeliveryPill } from './FreeDeliveryPill'

export type BoxPricesDiscountInfographicProps = {
  activeNumPortion: number
}

export const BoxPricesDiscountInfographic = ({
  activeNumPortion,
}: BoxPricesDiscountInfographicProps) => (
  <Box
    data-testid="box-prices-discount-infographic"
    display={Display.Flex}
    bg={Color.White}
    borderStyle={BorderStyle.Solid}
    borderWidth={0.5}
    borderRadius={1.5}
    borderColor={Color.NeutralGrey_200}
    alignItems={AlignItems.Center}
    flexDirection={FlexDirection.Column}
    width={['343px', '343px', '640px']}
  >
    <Space size={6} />

    <DiscountPerWeek />

    <Space size={2} />

    <FreeDeliveryPill />

    <Space size={4} />

    <Cta activeNumPortion={activeNumPortion} />

    <Space size={4} />
  </Box>
)
