import { AlignItems, Body1, Bold, Box, Display, Image } from '@gousto-internal/citrus-react'
import React from 'react'
import FareShareBadge from '../../../assets/EnvironmentalBenefitsSectionAssets/fareShareBadge.png'
import { ENVIRONMENTAL_BENEFITS_DONATION_COPY } from '../../../config'

export const DonationSection = () => (
  <Box display={Display.Flex} alignItems={AlignItems.Center}>
    <Box>
      <Image alt="Fare Share Badge" src={FareShareBadge} width="104px" />
    </Box>
    <Box paddingH={4}>
      <Body1>
        {ENVIRONMENTAL_BENEFITS_DONATION_COPY.firstLine} <br />
        <Bold>{ENVIRONMENTAL_BENEFITS_DONATION_COPY.secondLine}</Bold> <br />
        <Bold>{ENVIRONMENTAL_BENEFITS_DONATION_COPY.thirdLine}</Bold>
      </Body1>
    </Box>
  </Box>
)
