import { Action } from 'redux'

import { actionTypes } from 'actions/actionTypes'

export type PromoAction = Action<string> &
  Partial<{
    code: string
    promo: any
    errorText: string
    ageVerified: boolean
    visible: boolean
  }>

export const promosReducers = {
  promoCurrent: (state = '', action: PromoAction) => {
    switch (action.type) {
      case actionTypes.PROMO_SET:
        return action.code
      default:
        return state
    }
  },
  promoStore: (state = {}, action: PromoAction) => {
    switch (action.type) {
      case actionTypes.PROMO_RECEIVE:
        return { ...state, [action.promo.code]: action.promo }

      case actionTypes.PROMO_STORE_SAVE_ERROR:
        return action.code ? { ...state, [action.code]: { errorText: action.errorText } } : state

      default:
        return state
    }
  },
  promoAgeVerified: (state = false, action: PromoAction) => {
    switch (action.type) {
      case actionTypes.PROMO_AGE_VERIFY:
        return action.ageVerified

      default:
        return state
    }
  },
  promoModalVisible: (state = false, action: PromoAction) => {
    switch (action.type) {
      case actionTypes.PROMO_MODAL_VISIBILITY_CHANGE:
        return !!action.visible

      default:
        return state
    }
  },
}
