import React from 'react'

import {
  Bold,
  Box,
  Display,
  FlexDirection,
  JustifyContent,
  Text,
  TextAlign,
} from '@gousto-internal/citrus-react'
import { RafCampaignDetails } from '../../../hooks/useFetchRafCampaignDetails'

export const FriendGiftText = ({
  giftYourFriendCredit,
}: Pick<RafCampaignDetails, 'giftYourFriendCredit'>) => (
  <Box
    display={Display.Flex}
    flexDirection={FlexDirection.Column}
    justifyContent={JustifyContent.Center}
  >
    <Text textAlign={[TextAlign.Center, null, TextAlign.Left]} size={[2, 3]}>
      We&#39;ll also gift your friend <Bold>{giftYourFriendCredit} credit</Bold>
    </Text>
  </Box>
)
