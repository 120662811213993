import React from 'react'

import { Heading1 } from '@gousto-internal/citrus-react'
import { useSelector } from 'react-redux'

import { getBasketDate } from 'selectors/basket'

import { getMenuDeliveryDateText } from './utils'

type MenuDeliveryDateProps = {
  variant: string
}

export const MenuDeliveryDate = ({ variant }: MenuDeliveryDateProps) => {
  const basketDate = useSelector(getBasketDate)
  const text = getMenuDeliveryDateText(basketDate)

  return (
    <Heading1 size={6} data-testid={`menuDeliveryDate-${variant}`}>
      {text}
    </Heading1>
  )
}
