import React from 'react'

import { Heading1 } from '@gousto-internal/citrus-react'
import { useSelector } from 'react-redux'

import { getBasketDate } from 'selectors/basket'

import { getMenuDateRangeText } from './utils'

type MenuDateRangeProps = {
  variant: string
}

export const MenuDateRange = ({ variant }: MenuDateRangeProps) => {
  const basketDate = useSelector(getBasketDate)
  const text = getMenuDateRangeText(basketDate)

  return (
    <Heading1
      size={6}
      data-testid={`menuDateRange-${variant}`}
      data-testing={`menuDateRange-${variant}`}
    >
      {text}
    </Heading1>
  )
}
