import { Theme } from '@gousto-internal/citrus-react'
import { BrandTag } from '../../model/context/useRecipeBrand'

export const getBrandLabelColors = (tag: BrandTag, theme: Theme, isFineDineIn?: boolean) => {
  if (isFineDineIn || tag.slug === 'fine-dine-in-tagline') {
    return {
      ...tag.theme,
      color: theme.tokens.colors.NeutralGrey_50,
      backgroundColor: theme.tokens.colors.NeutralGrey_900,
    }
  }

  return {
    ...tag.theme,
    color: theme.tokens.colors.ColdGrey_800,
    backgroundColor: theme.tokens.colors.NeutralGrey_50,
  }
}
