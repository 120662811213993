import React from 'react'

import {
  Box,
  Modal,
  useModal,
  Heading3,
  Grid,
  Col,
  ModalClose,
} from '@gousto-internal/citrus-react'

import { useMenu } from 'routes/Menu/domains/menu'

import { PopularCategory } from '../PopularCategory'
import { POPULAR_CATEGORIES } from '../PopularCategory/constants'
import { SearchInput } from '../SearchInput'

export const recipeSearchModalName = 'RecipeSearchModal'

export const RecipeSearchModal = () => {
  const { closeCurrentModal } = useModal()
  const { getRecipesForCollectionId } = useMenu()

  return (
    <Modal
      name={recipeSearchModalName}
      preventScroll
      onRequestClose={() => {
        closeCurrentModal()
      }}
    >
      <Box paddingH={4} paddingBottom={4} paddingV={3}>
        <Box paddingRight={12}>
          <SearchInput />
        </Box>
        <ModalClose onClose={() => closeCurrentModal()} />
        <Box paddingV={3}>
          <Heading3 size={[4, null, 5]}> Popular Categories</Heading3>
        </Box>
        <Grid gap={[3, null, 4]}>
          {POPULAR_CATEGORIES.map((category) => {
            const collection = getRecipesForCollectionId(category.collectionId)
            const count = collection.recipes.size
            if (!collection || collection.recipes.size === 0) {
              return null
            }

            return (
              <Col size={[6, null, 4]} key={category.name}>
                <PopularCategory
                  categoryName={category.name}
                  imageUrl={category.imageUrl}
                  recipeCount={count}
                  collectionId={category.collectionId}
                />
              </Col>
            )
          })}
        </Grid>
      </Box>
    </Modal>
  )
}
