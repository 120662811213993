import React from 'react'

import {
  AlignItems,
  Color,
  Display,
  Icon,
  IconVariant,
  JustifyContent,
  Space,
} from '@gousto-internal/citrus-react'

import { PromoBannerContainer } from './components/PromoBannerContainer'

import { onEnter } from '../../util/onEnter'

type PromoBannerProps = {
  applyDiscount: () => void
}

export function PromoBanner({
  applyDiscount,
  children,
}: React.PropsWithChildren<PromoBannerProps>) {
  return (
    <PromoBannerContainer
      data-testid="promo-banner"
      alignItems={AlignItems.Center}
      bg={Color.Secondary_400}
      color={Color.White}
      display={Display.Flex}
      justifyContent={[JustifyContent.SpaceBetween]}
      onClick={applyDiscount}
      onKeyDown={onEnter(applyDiscount)}
      paddingH={[4, null, 6, 8, 10]}
      paddingV={4}
      role="button"
      tabIndex={0}
      width="100%"
    >
      {children}
      <Space direction="horizontal" size={2} />
      <Icon name="arrow_right" variant={IconVariant.Inherit} />
    </PromoBannerContainer>
  )
}
