import React from 'react'

import { Box, Display, JustifyContent } from '@gousto-internal/citrus-react'

import { SubscriptionTransparencyItem } from './SubscriptionTransparencyItem'

export const SubscriptionTransparency = () => (
  <Box
    display={Display.Flex}
    justifyContent={JustifyContent.Center}
    data-testid="subscription-transparency"
  >
    <Box width={['100%', '72%']}>
      <SubscriptionTransparencyItem
        highlightedText="No commitment. No cancellation fees."
        regularText="Skip a box or cancel your subscription online at anytime."
      />
    </Box>
  </Box>
)
