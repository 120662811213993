import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import redirectActions from 'actions/redirect'
import { client as clientRoutes } from 'config/routes'
import { useBasket } from 'routes/Menu/domains/basket'
import { useShouldShowDeliveryDetailsPage } from 'routes/Menu/hooks/useShouldShowDeliveryDetailsPage'

import { Menu } from './Menu'
import { menuOverlayClick } from './actions/menuOverlayClick'

const MenuWrapper = (ownProps: any) => {
  const dispatch = useDispatch()
  const { addRecipe, removeRecipe, date } = useBasket()
  const shouldShowDeliveryDetailsPage = useShouldShowDeliveryDetailsPage()
  const {
    params: { orderId },
  } = ownProps
  const shouldRedirectToDeliveryDetails = shouldShowDeliveryDetailsPage && !orderId && !date

  const onOverlayClick = React.useCallback(() => {
    dispatch(menuOverlayClick(removeRecipe))
  }, [dispatch, removeRecipe])

  useEffect(() => {
    if (shouldRedirectToDeliveryDetails) {
      dispatch(redirectActions.replace(clientRoutes.deliveryDetails))
    }
  }, [dispatch, shouldRedirectToDeliveryDetails])

  return shouldRedirectToDeliveryDetails ? null : (
    <Menu
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ownProps}
      addRecipeToBasket={addRecipe}
      onOverlayClick={onOverlayClick}
    />
  )
}

export { MenuWrapper }
