import React from 'react'
import {
  Body2,
  Box,
  Color,
  Display,
  DisplayPosition,
  Heading4,
  Icon,
  IconNames,
  JustifyContent,
  Image,
  FlexDirection,
  Overflow,
  Space,
} from '@gousto-internal/citrus-react'
import { convertDashToUnderscore } from '../../utils'
import type { Recipe } from '../../../../types/endlessVarietyCarousel'
import { ErrorBoundary } from 'react-error-boundary'

type SlideProps = {
  recipe: Recipe
}

enum DietaryClaimIconNames {
  DairyFree = 'dairy_free',
  GlutenFree = 'gluten_free',
  NutFree = 'nut_free',
  Vegan = 'vegan',
  Vegetarian = 'vegetarian',
}

export const Slide = ({ recipe }: SlideProps) => {
  return (
    <Box
      bg={Color.White}
      borderWidth={2}
      borderRadius={1.5}
      boxShadow="0 4px 4px rgba(0, 0, 0, 0.15)"
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      height="100%"
      overflowX={Overflow.Hidden}
      overflowY={Overflow.Hidden}
      width="100%"
    >
      <Box
        overflowX={Overflow.Hidden}
        overflowY={Overflow.Hidden}
        position={DisplayPosition.Relative}
      >
        <Image
          alt={recipe.title}
          src={recipe?.media?.images?.[0]?.urls?.[2]?.src}
          fit="cover"
          height="200px"
        />
        <Box
          display={Display.Flex}
          position={DisplayPosition.Absolute}
          paddingH={2}
          paddingV={2}
          top="0"
        >
          {recipe?.dietaryClaims?.reduce((acc: JSX.Element[], claim, index) => {
            const isDietaryClaimIconAvailable = Object.values(DietaryClaimIconNames).includes(
              convertDashToUnderscore(claim.slug) as DietaryClaimIconNames,
            )
            if (isDietaryClaimIconAvailable) {
              acc.push(
                <ErrorBoundary key={`${claim.slug}-${index}`} fallback={<></>}>
                  <Box height="28px" width="28px" paddingRight={4} data-testid="dietary-claim">
                    <Icon name={convertDashToUnderscore(claim.slug) as IconNames} size={8} />
                  </Box>
                  <Space direction="horizontal" size={1} />
                </ErrorBoundary>,
              )
            }
            return acc
          }, [])}
        </Box>
      </Box>
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        justifyContent={JustifyContent.SpaceBetween}
        minHeight={['115px', '125px']}
        paddingH={3}
        paddingV={4}
        flexGrow={1}
      >
        <Box paddingBottom={2}>
          <Heading4 size={[3, null, 5]}>{recipe.title}</Heading4>
        </Box>
        <Box display={Display.Flex}>
          <Box>
            <Body2>{recipe.prepTimes?.for2} mins</Body2>
          </Box>
          {recipe.rating?.average > 0 && recipe.rating?.count > 0 && (
            <>
              <Body2>&nbsp;|</Body2>
              <Box paddingH={1}>
                <Icon name="rate_full" size={5} />
              </Box>
              <Body2>
                {recipe.rating?.average} ({recipe.rating?.count})
              </Body2>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}
