import React, { useContext, useState } from 'react'

import {
  AlignItems,
  Body1,
  Body2,
  Bold,
  Box,
  Color,
  Display,
  FontFamily,
  Icon,
  JustifyContent,
  Link,
  useModal,
} from '@gousto-internal/citrus-react'

import { SubscriptionContext } from '../../../context'
import { actionTypes } from '../../../context/reducers'
import { getDeliveryFrequency } from '../../../context/selectors/deliveries'
import { trackSubscriptionSettingsChange } from '../../../tracking'
import { generateNextDeliveryFrequencyString } from '../../generateNextDeliveryFrequencyString'
import { CalendarDeliverDay } from '../../hooks/useSubscriptionCalendar'
import { DeliveryDays } from '../../hooks/useSubscriptionToDeliveryDays'
import { CalendarModal, SUBSCRIPTION_CALENDAR_MODAL_NAME } from '../../modals/CalendarModal'
import { FrequencyKey } from '../../types'
import { dateFormatter } from '../../utils/dateFormatter'

type Props = { subscriptionToDays: DeliveryDays }

export const NextDeliverySection = (props: Props) => {
  const dateFormatterModule = dateFormatter()
  const { subscriptionToDays } = props
  const { state, dispatch } = useContext<any>(SubscriptionContext)

  const currentDeliveryFrequency = getDeliveryFrequency(state) as FrequencyKey
  const [selectedDeliveryDayFromCalendar, setSelectedDeliveryDayFromCalendar] =
    useState<CalendarDeliverDay | null>(null)
  const { openModal } = useModal()
  const previousSelectedDate = subscriptionToDays.deliveryDate

  const deliveryDateCopy = !selectedDeliveryDayFromCalendar
    ? previousSelectedDate
    : selectedDeliveryDayFromCalendar.date

  const nextDeliveryFrequencyString = generateNextDeliveryFrequencyString(
    currentDeliveryFrequency,
    !selectedDeliveryDayFromCalendar ? previousSelectedDate : selectedDeliveryDayFromCalendar.date,
  )
  const onNextDeliveryClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    openModal(SUBSCRIPTION_CALENDAR_MODAL_NAME)
    trackSubscriptionSettingsChange({ settingName: 'reactivate_delivery_date', action: 'edit' })({
      previous_date: previousSelectedDate,
    })
  }

  const updateSelectedDayFromCalendar = (day: CalendarDeliverDay) => {
    setSelectedDeliveryDayFromCalendar(day)
    dispatch({
      type: actionTypes.UPDATE_CALENDAR_DELIVERY_DATE,
      data: { slotId: day.slotId },
    })
  }

  return (
    <>
      <CalendarModal
        deliveryDays={subscriptionToDays.data}
        preSelectedDay={previousSelectedDate}
        updateSelectedDayFromCalendar={updateSelectedDayFromCalendar}
        showModal={false}
      />

      <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
        <Box width={['2.5rem']}>
          <Icon name="calendar" />
        </Box>
        <Box width={['100%']}>
          <Body1 fontFamily={FontFamily.Bold} color={Color.ColdGrey_800}>
            Next Delivery
          </Body1>
          <Body2>
            <span>
              <Bold color={Color.ColdGrey_800}>
                {/* TODO: write a test that checks this string is correct in ui */}
                {dateFormatterModule.getFormattedMonthDayWithWeekday(String(deliveryDateCopy))}
              </Bold>{' '}
              {nextDeliveryFrequencyString}
            </span>
          </Body2>
        </Box>
        <Box
          width={['3rem']}
          display={Display.Flex}
          alignItems={AlignItems.Baseline}
          justifyContent={JustifyContent.FlexEnd}
        >
          <Link
            href="#next-delivery"
            onClick={onNextDeliveryClick}
            data-testid="nextDeliveryEditButton"
          >
            Edit
          </Link>
        </Box>
      </Box>
    </>
  )
}
