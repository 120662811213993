import React from 'react'

import {
  AlignItems,
  Bold,
  Box,
  Button,
  Color,
  Display,
  DisplayPosition,
  FlexDirection,
  Icon,
  JustifyContent,
  Space,
  Text,
  ZIndex,
} from '@gousto-internal/citrus-react'
import { FloatingCTA } from '../../Hero.config'

export type GetStartedProps = {
  signupCTA: string
  onCTAClick: () => void
}

export const GetStartedFloatingCTA = ({ signupCTA, onCTAClick }: GetStartedProps) => {
  const { floatingCTABoldText, floatingCTAText } = FloatingCTA

  return (
    <section data-testid="getStartedFloatingCTA">
      <Box
        bg={Color.White}
        position={DisplayPosition.Fixed}
        display={[Display.Flex, Display.None, null]}
        bottom="0"
        left="0"
        right="0"
        flexDirection={FlexDirection.Column}
        paddingV={3}
        paddingH={3}
        boxShadow="0 -2px 16px rgba(0, 0, 0, 0.2)"
        zIndex={ZIndex.Sticky}
      >
        <Button onClick={onCTAClick} width="100%" height="48px">
          {signupCTA}
        </Button>
        <Space size={3} />
        <Box
          role="img"
          aria-label="No lock in icon"
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          alignItems={AlignItems.Center}
          paddingBottom={2}
          justifyContent={JustifyContent.Center}
        >
          <Icon name="play_pause" size={6} />
          <Space direction="horizontal" size={2} />
          <Text size={1}>
            <Bold>{floatingCTABoldText}</Bold>
            {floatingCTAText}
          </Text>
        </Box>
      </Box>
    </section>
  )
}
