import { useSelector } from 'react-redux'

import {
  BPPC_GIFTING_EXPERIMENT_PROMO_CODE_DATA,
  GIFTING_EXPERIMENT_PROMO_CODE_DATA,
} from 'routes/Home/homeConfig'
import { useDiscountDescriptor } from 'routes/Menu/components/BoxSummary/Banner/PriceAndDiscountTip/priceAndDiscountTipUtils'
import { getPromoCode } from 'selectors/basket'

import { formatDiscountTip } from './discountAppliedBarUtils'

export const useDiscountAppliedData = () => {
  const discountDescriptor = useDiscountDescriptor()
  const { isDiscountEnabled } = discountDescriptor
  const basketPromo = useSelector(getPromoCode)
  const isGiftDiscountApplied =
    basketPromo === GIFTING_EXPERIMENT_PROMO_CODE_DATA.defaultPromoCode ||
    basketPromo === BPPC_GIFTING_EXPERIMENT_PROMO_CODE_DATA.defaultPromoCode

  const discountAmount = formatDiscountTip(discountDescriptor)

  const discountTip = isGiftDiscountApplied
    ? `${discountAmount} plus FREE DESSERT for three months`
    : discountAmount

  return {
    discountTip,
    isDiscountEnabled,
  }
}
