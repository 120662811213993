import React from 'react'

import Link from 'Link'
import classnames from 'classnames'
import { Button } from 'goustouicomponents'
import Immutable from 'immutable'
import PropTypes from 'prop-types'

import routes from 'config/routes'

import SeeMenuSection from './SeeMenuSection'

import css from './NoOrders.css'

const NoOrders = ({ recipes, boxType }) => (
  <div>
    <div className={css.orderWrap}>
      <div className={classnames(css.order, css.noShadow)}>
        <p className={css.messageCopy}>You have no upcoming deliveries</p>
      </div>
    </div>
    <div className={css.orderWrap}>
      <div className={css.order}>
        <div>
          <p className={css.twrCopy}>Check out a selection of this week&apos;s menu</p>
        </div>
        <div>
          <SeeMenuSection recipes={recipes} boxType={boxType} />
        </div>
        <div className={css.buttonRow}>
          <Link to={`${routes.client.menu}`} clientRouted={false}>
            <Button color="secondary" noDecoration>
              See full menu
            </Button>
          </Link>
        </div>
      </div>
    </div>
  </div>
)

NoOrders.propTypes = {
  recipes: PropTypes.instanceOf(Immutable.Map),
  boxType: PropTypes.string,
}
NoOrders.defaultProps = {
  recipes: Immutable.Map({}),
  boxType: '',
}

export default NoOrders
