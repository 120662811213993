/**
 * This code was migrated from webclient. For the original file's history, see
 * https://github.com/Gousto/gousto-webclient/blob/1544f0f8/src/apps/webclient/src/config/service-environment/service-environment.types.ts
 */

import type { Domain, PROTOCOL_PREFIX } from '@library/environment'
import type { Nullable } from '@library/type-utils'

export type ServiceVersion = {
  version: string
  majorVersion: number
  basePath: string
}

/* eslint-disable no-shadow,no-unused-vars */
export enum OVERRIDDEN_SERVICE_PATTERNS {
  loggingmanager = 'loggingmanager',
  webclient = 'webclient',
}

export type ServiceName =
  | 'auth'
  | 'brand'
  | 'clientmetrics'
  | 'complaints'
  | 'cookbook'
  | 'core'
  | 'customers'
  | 'deliveries'
  | 'loggingmanager'
  | 'menu'
  | 'order'
  | 'orders'
  | 'orderskiprecovery'
  | 'payments'
  | 'paymentmethodquery'
  | 'products'
  | 'ssr'
  | 'ssrrecipecards'
  | 'subpauseosr'
  | 'subscriptioncommand'
  | 'subscriptionquery'
  | 'tastepreferences'
  | 'userbucketing'
  | 'userfeedback'
  | 'webclient'
  | 'workable'

export type ServiceManifest = {
  [key in ServiceName]: ServiceVersion[]
}

export enum SERVICE_DOMAINS {
  local = 'gousto.local',
  production = 'gousto.co.uk',
  default = 'gousto.info',
}

export type ServiceUrlProperties = {
  basePath?: Nullable<string>
  majorVersion?: number
  environmentName: string
  port?: number
  protocol: PROTOCOL_PREFIX
  serviceName: ServiceName
  serviceDomain: Domain
}

export type ServiceEnvironment = {
  environmentName: string
  protocol: PROTOCOL_PREFIX
  serviceDomain: Domain
}
