import React from 'react'

import { Box, Link, Text } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { trackUTMAndPromoCode } from 'actions/tracking'
import { checkoutClickPrivacyPolicy } from 'actions/trackingKeys'
import { client } from 'config/routes'

export const PrivacyPolicy = () => {
  const dispatch = useDispatch()

  return (
    <Box paddingV={[4, 4, 8]} paddingBottom={[0, 0, 0]}>
      <Text size={[0, 0, 2]}>
        {'You can unsubscribe at any time, and manage your preferences in your account. '}
        {'We do not share your information with other companies for their direct '}
        {'marketing purposes, and always process your information in line with our '}
        <Link
          data-testid="checkoutPrivacyPolicy"
          size={[0, 0, 2]}
          href={client.privacyPolicy}
          target="_blank"
          onClick={() => dispatch(trackUTMAndPromoCode(checkoutClickPrivacyPolicy))}
        >
          privacy policy.
        </Link>
      </Text>
    </Box>
  )
}
