export const POPULAR_CATEGORIES = [
  {
    name: 'New & Limited',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/NewLimited-Foreground-v3.png',
    collectionId: '56b88b66-7e0b-11ec-a129-02e1cea636b3',
  },
  {
    name: 'Quick & Easy',
    imageUrl: 'https://production-media.gousto.co.uk/collection-images/QuickEasy-Foreground-v3.png',
    collectionId: 'b6606d9e-2dbb-11ed-a194-022218cc141f',
  },
  {
    name: 'Health Hub',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/HealthyChoices-Foreground-v3.png',
    collectionId: '1b24de1c-3473-11ef-8044-06a0257ab9ff',
  },
  {
    name: 'Everyday Favourites',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/Everyday-Favourites-foreground.png',
    collectionId: 'e55383da-336d-11e9-b24d-06399ef4685e',
  },
  {
    name: 'Vegeterian',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/Vegetarian-Foreground-v3.png',
    collectionId: '5c117c20-8b05-11e6-8538-065f01f5b2df',
  },
  {
    name: 'Save & Savour',
    imageUrl:
      'https://production-media.gousto.co.uk/collection-images/SaveSavour-Foreground-v3.png',
    collectionId: '6c5806f2-61b5-11ed-a541-027900d93479',
  },
]
