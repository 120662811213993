import { useDecision } from '@optimizely/react-sdk'

import { useAuth } from 'routes/Menu/domains/auth'

export function useIsCheckoutFlexibilityAwarenessEnabled() {
  const [isCheckoutFlexibilityAwarenessEnabled] = useDecision(
    'beetroots_ofx_checkout_flexibility_awareness',
  )
  const { isAuthenticated } = useAuth()
  if (isAuthenticated) return false

  return isCheckoutFlexibilityAwarenessEnabled.variationKey === 't1'
}
