import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { frequencyMapping } from '../../../../enum/frequency'
import { FrequencyOptions } from './FrequencyOptions'
import {
  SubscriptionContext,
} from '../../../../context'

import {
  getIsSubscriptionLoaded
} from '../../../../context/selectors/subscription'

import { getDeliveryFrequency } from '../../../../context/selectors/deliveries'
import { SettingSection } from '../../../../components/SettingSection'

import { useUpdateSubscription } from '../../../../hooks/useUpdateSubscription'
import { useSubscriptionToast } from '../../../../hooks/useSubscriptionToast'
import { trackSubscriptionSettingsChange } from '../../../../tracking'

export const Frequency = ({ accessToken, isMobile }) => {
  const subscriptionContext = useContext(SubscriptionContext)
  const { state: subscriptionState } = subscriptionContext

  const currentDeliveryFrequency = getDeliveryFrequency(subscriptionState).toString()

  const [selectedInterval, setSelectedInterval] = useState(currentDeliveryFrequency)
  const [shouldSubmit, setShouldSubmit] = useState(false)

  const isSubscriptionLoaded = getIsSubscriptionLoaded(subscriptionState)
  const settingName = 'box_frequency'

  const [, updateResponse, updateError] = useUpdateSubscription({
    accessToken,
    trigger: {
      shouldRequest: shouldSubmit,
      setShouldRequest: setShouldSubmit
    },
    data: {
      interval: parseInt(selectedInterval, 10)
    },
    settingName
  })

  useSubscriptionToast(updateResponse, updateError)

  const onChange = ({ target: { value } }) => setSelectedInterval(value)

  const onSubmit = () => {
    trackSubscriptionSettingsChange({ settingName, action: 'update' })()
    setShouldSubmit(true)
  }

  const isCtaDisabled = selectedInterval === currentDeliveryFrequency || !selectedInterval

  return (
    <SettingSection
      icon="frequency"
      title="Frequency"
      instruction="Choose frequency"
      ctaText="Save frequency"
      isCtaDisabled={isCtaDisabled}
      renderCurrentValue={(
        <p
          data-testing="current-frequency"
        >
          {frequencyMapping[selectedInterval] || frequencyMapping[currentDeliveryFrequency]}
        </p>
      )}
      onSubmit={onSubmit}
      onEditClick={trackSubscriptionSettingsChange({ settingName, action: 'edit' })}
      isMobile={isMobile}
      testingSelector="box-frequency"
    >
      {isMobile ? (
        <p data-testing="box-frequency-instruction-text">
          Please select the how often you’d like to receive your box.
        </p>
      ) : null}

      { isSubscriptionLoaded ? (
        <FrequencyOptions selectedFrequency={selectedInterval} onChange={onChange} />
      ) : null}
    </SettingSection>
  )
}

Frequency.propTypes = {
  accessToken: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired
}
