import React from 'react'

import { Heading3, Space } from '@gousto-internal/citrus-react'
import { withOptimizely, ReactSDKClient } from '@optimizely/react-sdk'
import { Button, Heading, LayoutContentWrapper, Spinner } from 'goustouicomponents'
import Immutable from 'immutable'
import PropTypes from 'prop-types'

import { Receipt } from 'components/Receipt'
import { UserCreditMessage } from 'components/UserCreditMessage'
import { basketSum } from 'utils/basket'

import { BoxProgressAlert } from './BoxProgressAlert'
import { DateHeader } from './DateHeader'
import { DetailsCheckoutButton } from './DetailsCheckoutButton'
import { PortionsButton } from './PortionsButton'
import { RecipeList } from './RecipeList'
import {
  HIDE_CHOOSE_RECIPES_CTA,
  HIDE_RECIPE_LIST,
  HIDE_PORTIONS,
  HIDE_PROMO_CODE_TEXT,
} from './displayOptionsProps'

import css from './Details.css'

class DetailsComponent extends React.Component {
  getCtaText = (numRecipes, maxRecipesNum, minRecipesNum) => {
    let text = ''

    if (numRecipes < maxRecipesNum) {
      text = 'Choose more recipes'
      if (numRecipes < minRecipesNum) {
        text = 'Choose recipes'
      }
    }

    return text
  }

  renderPortions = ({ numPortions }) => <PortionsButton numPortions={numPortions} />

  renderPromoCodeMessage = () => {
    const { accessToken, displayOptions, promoCode } = this.props

    if (accessToken || displayOptions.contains(HIDE_PROMO_CODE_TEXT) || promoCode) {
      return null
    }

    return <p className={css.supportingText}>You can enter promo codes later.</p>
  }

  render() {
    const {
      displayOptions,
      numPortions,
      pricingPending,
      prices,
      okRecipeIds,
      view,
      orderId,
      date,
      clearSlot,
      boxSummaryVisibilityChange,
      deliveryDays,
      slotId,
      shouldDisplayFullScreenBoxSummary,
      maxRecipesForPortion,
      minRecipesForPortion,
      removeRecipeFromBasket,
      basketIsShowDetailsViewFirstChange,
      shouldShowDeliveryDetails,
    } = this.props

    const {
      deliveryTotal,
      surchargeTotal,
      recipeTotal,
      total,
      recipeDiscount,
      percentageOff,
      productTotal,
    } = prices || {}

    const numRecipes = basketSum(okRecipeIds)
    const maxRecipesNum = maxRecipesForPortion(numPortions)
    const minRecipesNum = minRecipesForPortion(numPortions)
    const ctaText = this.getCtaText(numRecipes, maxRecipesNum, minRecipesNum)
    const showSecondCta = numRecipes > 1 && numRecipes < 4 && shouldDisplayFullScreenBoxSummary
    const displayCta =
      !displayOptions.contains(HIDE_CHOOSE_RECIPES_CTA) && ctaText && !showSecondCta
    let btnClassName = css.ctaButton
    if (shouldDisplayFullScreenBoxSummary) {
      btnClassName = css.stickyButton
    }

    const handleClearSlot = () => {
      basketIsShowDetailsViewFirstChange(false)
      clearSlot()
    }

    return (
      <React.Fragment>
        <LayoutContentWrapper>
          <Heading isCenter size="_legacy_large" type="h2">
            Order Summary
          </Heading>
          {(!shouldShowDeliveryDetails || orderId) && (
            <>
              <DateHeader
                orderId={orderId}
                date={date}
                clearSlot={handleClearSlot}
                deliveryDays={deliveryDays}
                slotId={slotId}
              />
              {!displayOptions.contains(HIDE_PORTIONS) && this.renderPortions(this.props)}
            </>
          )}
        </LayoutContentWrapper>
        <LayoutContentWrapper>
          <p className={css.titleSection}>Chosen recipes</p>
          {numRecipes === 0 && (
            <p>
              Add up to {maxRecipesNum} recipes to create your Gousto order. The more you add, the
              lower the price per portion.
            </p>
          )}
        </LayoutContentWrapper>
        {
          // eslint-disable-next-line react/jsx-props-no-spreading
          !displayOptions.contains(HIDE_RECIPE_LIST) && <RecipeList {...this.props} />
        }
        <LayoutContentWrapper>
          <BoxProgressAlert numRecipes={numRecipes} />
          {showSecondCta && (
            <Button
              color="secondary"
              onClick={() => {
                boxSummaryVisibilityChange(false, removeRecipeFromBasket)
              }}
              width="full"
            >
              {ctaText}
            </Button>
          )}
          {pricingPending ? (
            <div className={css.spinner}>
              <Spinner color="black" />
            </div>
          ) : (
            <div>
              <Heading3 size={7}>Order total</Heading3>
              <Space size={4} />
              <Receipt
                dashPricing={numRecipes < minRecipesNum}
                numRecipes={numRecipes}
                numPortions={numPortions}
                prices={prices}
                deliveryTotalPrice={deliveryTotal}
                surchargeCount={prices?.surchargeCount}
                surchargeTotal={surchargeTotal}
                recipeTotalPrice={recipeTotal}
                totalToPay={total}
                recipeDiscountAmount={recipeDiscount}
                recipeDiscountPercent={percentageOff}
                extrasTotalPrice={productTotal}
              />
              <UserCreditMessage />
            </div>
          )}
          {this.renderPromoCodeMessage()}

          <DetailsCheckoutButton
            btnClassName={btnClassName}
            displayCta={!!displayCta}
            ctaText={ctaText}
            view={view}
            onClick={() => {
              boxSummaryVisibilityChange(false, removeRecipeFromBasket)
            }}
          />
        </LayoutContentWrapper>
      </React.Fragment>
    )
  }
}

DetailsComponent.propTypes = {
  accessToken: PropTypes.string,
  maxRecipesForPortion: PropTypes.func.isRequired,
  minRecipesForPortion: PropTypes.func.isRequired,
  basketIsShowDetailsViewFirstChange: PropTypes.func.isRequired,
  boxSummaryVisibilityChange: PropTypes.func.isRequired,
  clearSlot: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  deliveryDays: PropTypes.instanceOf(Immutable.Map).isRequired,
  displayOptions: PropTypes.instanceOf(Immutable.List),
  numPortions: PropTypes.number.isRequired,
  orderId: PropTypes.string.isRequired,
  promoCode: PropTypes.string,
  okRecipeIds: PropTypes.instanceOf(Immutable.Map).isRequired,
  recipesStore: PropTypes.instanceOf(Immutable.Map).isRequired,
  slotId: PropTypes.string,
  view: PropTypes.string,
  menuFetchPending: PropTypes.bool.isRequired,
  orderSaveError: PropTypes.string,
  pricingPending: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  prices: PropTypes.object,
  unavailableRecipeIds: PropTypes.instanceOf(Immutable.Map).isRequired,
  showRecipeDetailsOnClick: PropTypes.func,
  shouldDisplayFullScreenBoxSummary: PropTypes.bool.isRequired,
  removeRecipeFromBasket: PropTypes.func.isRequired,
  isTestUser: PropTypes.bool.isRequired,
  numRecipesOverLimit: PropTypes.number.isRequired,
  optimizely: PropTypes.shape(ReactSDKClient),
  shouldShowDeliveryDetails: PropTypes.bool.isRequired,
}

DetailsComponent.defaultProps = {
  view: 'desktop',
  accessToken: '',
  displayOptions: Immutable.List([]),
  prices: {},
  pricingPending: false,
  showRecipeDetailsOnClick: () => {},
  promoCode: null,
  slotId: null,
  orderSaveError: null,
  optimizely: null,
}

const Details = withOptimizely(DetailsComponent)

export { Details }
