import {
  AlignItems,
  AlignSelf,
  Box,
  Color,
  Display,
  Display1,
  FlexDirection,
  FontFamily,
  Image,
  JustifyContent,
  Space,
  Standfirst1,
  Text,
  TextAlign,
  Visible,
  useTheme,
} from '@gousto-internal/citrus-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { canUseWindow } from '@library/environment'
import { queueTrackingEvent } from '@library/tracking-integration'
import { debounce } from 'lodash'
import { RafLandingPageType, SkipTheWizardExperimentType } from '../../Pages/RafLandingPage'
import { RafCampaignDetails } from '../../hooks/useFetchRafCampaignDetails'
import { RafTrackingEventType, StickyTrackingEvent } from '../../tracking/trackingConfig'
import { HERO_CONFIG, IMAGES } from './Hero.config'
import { CTABlock, DiscountBoxes, FriendGiftText } from './components/'
import {
  GetStartedFloatingCTA,
  GetStartedProps,
} from './components/GetStartedFloatingCTA/GetStartedFloatingCTA'

type HeroProps = {
  isRafLandingPageExperimentEnabled: boolean
}

export const Hero = ({
  firstBoxDiscount,
  secondBoxDiscount,
  giftYourFriendCredit,
  onScrollHandler,
  isSkipTheWizardEnabled,
  onCTAClick,
  signupCTA,
  isRafLandingPageExperimentEnabled,
}: HeroProps &
  GetStartedProps &
  RafCampaignDetails &
  SkipTheWizardExperimentType &
  Pick<RafLandingPageType, 'onScrollHandler'>) => {
  const [isSticky, setIsSticky] = useState(false)
  const [_maxHeight, setMaxHeight] = useState(0)
  const { theme } = useTheme()
  const breakpointToMedium = Number(theme.tokens.breakpoints[0].replace('px', ''))

  const heroRef = useRef<HTMLDivElement>(null)
  const stickyCTARef = useRef<HTMLDivElement>(null)
  const [isHeroInView, setIsHeroInView] = useState(true)

  const onScroll = useCallback(() => {
    onScrollHandler({ heroRef, stickyCTARef, setIsSticky, setMaxHeight })
  }, [onScrollHandler])

  const debounceScroll = debounce(onScroll, 200)

  useEffect(() => {
    if (canUseWindow()) {
      window?.addEventListener('scroll', debounceScroll)
    }

    if (window?.innerWidth < breakpointToMedium) {
      queueTrackingEvent<RafTrackingEventType>(
        StickyTrackingEvent({ isVisible: isSticky, name: 'sticky_footer_cta_with_discount' }),
      )
    }

    return () => {
      if (canUseWindow()) {
        window.removeEventListener('scroll', debounceScroll)
      }
    }
  }, [breakpointToMedium, debounceScroll, isSticky])

  useEffect(() => {
    if (!canUseWindow) return

    const observer = new IntersectionObserver(([entry]) => setIsHeroInView(entry.isIntersecting), {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    })

    if (heroRef.current) {
      observer.observe(heroRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <Box
      paddingH={[3, 8]}
      paddingV={[0, null, 14]}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      bg={Color.ColdGrey_50}
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
      ref={heroRef}
    >
      <Box
        display={Display.Flex}
        alignItems={[AlignItems.Center, null, AlignItems.FlexStart]}
        justifyContent={JustifyContent.Center}
        flexDirection={[FlexDirection.Column, null, FlexDirection.Row]}
        width="100%"
        maxWidth="1200px"
        className="main"
        paddingH={[null, null, null, 10]}
        gap={[2, 0, 0]}
      >
        <Visible display={[Display.Block, null, Display.None]}>
          <Box maxWidth="360px" justifyContent={JustifyContent.Center}>
            <Image
              fit="contain"
              srcSet={`${IMAGES.mobile.webp.x1} 1x, ${IMAGES.mobile.webp.x2} 2x`}
              src={IMAGES.mobile.png}
              alt=""
            />
          </Box>
        </Visible>
        <Box
          justifyContent={[JustifyContent.Center, null, JustifyContent.FlexStart]}
          alignItems={AlignItems.FlexStart}
          display={Display.Flex}
          width={['100%', null, null, '40%']}
        >
          <Box>
            <Display1 textAlign={[TextAlign.Center, null, TextAlign.Left]} size={[13, null, 14]}>
              {HERO_CONFIG.heading}
            </Display1>
            <Box
              justifyContent={[JustifyContent.Center, null, JustifyContent.FlexStart]}
              display={Display.Flex}
              width="100%"
            >
              <Box
                paddingV={[1, 3]}
                display={Display.Flex}
                flexDirection={FlexDirection.Column}
                maxWidth={[null, '95%', '100%', '80%']}
                justifyContent={JustifyContent.Center}
              >
                <Standfirst1
                  color={Color.ColdGrey_800}
                  size={[5, null, 8]}
                  textAlign={[TextAlign.Center, null, TextAlign.Left]}
                >
                  Over{' '}
                  <Text
                    size={[5, null, 8]}
                    color={Color.ColdGrey_800}
                    display={Display.Inline}
                    fontFamily={FontFamily.Bold}
                  >
                    a million{' '}
                  </Text>
                  people have signed up for Gousto thanks to their mates
                </Standfirst1>
              </Box>
            </Box>
            <Visible display={[Display.None, null, Display.Block]}>
              <Box id="friendGiftSection" paddingV={[0, 1]} paddingBottom={[0, 5]}>
                <FriendGiftText giftYourFriendCredit={giftYourFriendCredit} />
              </Box>
            </Visible>
            <Visible display={[Display.None, null, Display.Block]}>
              <CTABlock isSkipTheWizardEnabled={isSkipTheWizardEnabled} />
            </Visible>
          </Box>
        </Box>
        <Space direction="horizontal" size={4} />
        <Box display={Display.Flex} gap={[2, null, 0]}>
          <DiscountBoxes
            firstBoxDiscount={firstBoxDiscount}
            secondBoxDiscount={secondBoxDiscount}
          />
          <Visible display={[Display.None, null, Display.Block]}>
            <Box display={Display.Flex} alignSelf={AlignSelf.Start} paddingH={1}>
              <Image
                srcSet={`${IMAGES.desktop.webp.x1} x1, ${IMAGES.desktop.webp.x2} x2`}
                src={IMAGES.desktop.png}
                alt=""
                fit="contain"
              />
            </Box>
          </Visible>
        </Box>
      </Box>
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        justifyContent={JustifyContent.Center}
        alignItems={AlignItems.Center}
        width="100%"
      >
        <Visible display={[Display.Block, null, Display.None]}>
          <Space direction="vertical" size={4} />
          <FriendGiftText giftYourFriendCredit={giftYourFriendCredit} />
          <Space direction="vertical" size={4} />
          <CTABlock isSkipTheWizardEnabled={isSkipTheWizardEnabled} />
          <Space direction="vertical" size={6} />
        </Visible>
      </Box>
      {isRafLandingPageExperimentEnabled && !isHeroInView && (
        <GetStartedFloatingCTA signupCTA={signupCTA} onCTAClick={onCTAClick} />
      )}
    </Box>
  )
}
