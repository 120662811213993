import React, { useState, useContext } from 'react'

import {
  Modal,
  ModalClose,
  ModalHeader,
  ModalBody,
  ModalButton,
  Heading5,
  ButtonColorVariant,
  Text,
  ButtonSize,
  Space,
  FontFamily,
} from '@gousto-internal/citrus-react'

import {
  getNumPortions,
  getSelectedBoxSize,
  getSubscriptionSettingsUnsupported,
} from 'routes/Account/Subscription/context/selectors/box'
import { getOpenOrders } from 'routes/Account/Subscription/context/selectors/orders'
import { getIsSubscriptionActive } from 'routes/Account/Subscription/context/selectors/subscription'
import { getSubscriptionBoxSizeOptions } from 'routes/Account/Subscription/context/selectors/subscriptionOptions'

import { SubscriptionContext } from '../../../../context/index'
import { actionTypes } from '../../../../context/reducers'
import { useUpdateSubscription } from '../../../../hooks/useUpdateSubscription'
import { BoxSizeConfirmationModal } from '../BoxSize/BoxSizeConfirmationModal'

interface Props {
  accessToken: string
}

interface BoxSize {
  numPortions: number
  numRecipes: number[]
}

interface UpdateResponseType {
  status: string
}

export const validationModal = 'validation-modal'

const ValidationModal = ({ accessToken }: Props) => {
  const context = useContext<any>(SubscriptionContext)
  const { state, dispatch } = context
  const [shouldSubmit, setShouldSubmit] = useState(false)
  const numPortions = getSelectedBoxSize(state)
  const originalPortions = getNumPortions(state)
  const boxSize = getSubscriptionBoxSizeOptions(state)
  const isSubscriptionActive = getIsSubscriptionActive(state)
  const isUnsupported = getSubscriptionSettingsUnsupported(state)
  let maxRecipes = 0
  let minRecipes = 0
  if (boxSize.length > 0) {
    const matchingBoxSize = boxSize.find(
      (size: BoxSize) => size.numPortions === Number(numPortions),
    )

    const { numRecipes } = matchingBoxSize
    if (matchingBoxSize) {
      // eslint-disable-next-line prefer-destructuring
      minRecipes = numRecipes[0]
      maxRecipes = numRecipes[matchingBoxSize.numRecipes.length - 1]
    }
  }

  const [, updateResponse, updateError] = useUpdateSubscription({
    accessToken,
    trigger: {
      shouldRequest: shouldSubmit,
      setShouldRequest: setShouldSubmit,
    },
    data: {
      numRecipes: String(maxRecipes),
      numPortions,
    },
    settingName: 'modal_update_to_max_recipes_per_portion_size',
  }) as [any, UpdateResponseType | null, boolean | null]

  const didUpdateComplete = typeof updateResponse?.status === 'string'

  const onSettingsUpdate = () => {
    dispatch({ type: actionTypes.SWITCH_TO_MAX_RECIPES_PER_PORTION_SIZE })

    if (isSubscriptionActive) {
      setShouldSubmit(true)
    }
  }

  const onCancel = () => {
    dispatch({
      type: actionTypes.UPDATE_SELECTED_BOX_SIZE,
      data: { numPortions: originalPortions },
    })
  }

  const formattedValidBoxSizes = `${minRecipes} to ${maxRecipes}`

  return (
    <>
      <Modal preventScroll isOpen={isUnsupported} name={validationModal}>
        <ModalHeader>
          <Heading5>{`${numPortions} person boxes fit ${formattedValidBoxSizes} recipes`}</Heading5>
          <ModalClose onClose={onCancel} />
        </ModalHeader>
        <ModalBody>
          <Text
            fontFamily={FontFamily.Bold}
            size={1}
          >{`Your default recipe box will change to ${maxRecipes} recipes.`}</Text>
          <Space size={2} direction="vertical" />
          <Text size={1}>
            This is the box we create for you if you don&apos;t choose your own recipes.
          </Text>
          <Space size={5} direction="vertical" />
          <ModalButton
            colorVariant={ButtonColorVariant.Primary}
            onClick={onSettingsUpdate}
            size={ButtonSize.Medium}
            width="100%"
          >
            OK
          </ModalButton>
        </ModalBody>
      </Modal>
      <BoxSizeConfirmationModal
        openOrders={getOpenOrders(state)}
        numPortions={numPortions}
        didUpdateComplete={didUpdateComplete}
        didUpdateFail={updateError}
      />
    </>
  )
}

export { ValidationModal }
