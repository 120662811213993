import React from 'react'

import { CSSObject } from '@emotion/react'
import {
  AlignItems,
  Box,
  Col,
  Color,
  Display,
  FontWeight,
  Grid,
  Space,
  Text,
  TextAlign,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

export const DiscountPerWeek = () => {
  const discount = [
    '60% OFF',
    '20% OFF',
    '20% OFF',
    '20% OFF',
    '20% OFF',
    '20% OFF',
    '20% OFF',
    '20% OFF',
  ]

  const discountWeeks = [
    'Today',
    'Week 2',
    'Week 3',
    'Week 4',
    'Week 5',
    'Week 6',
    'Week 7',
    'Week 8',
  ]

  const StyledGrid = withCitrusTheme(Grid, (theme) => ({
    ...(theme.responsiveSpacing([0, 0, 8], ['paddingLeft']) as CSSObject),
  }))

  return (
    <Box display={Display.Flex} data-testid="discount-per-week">
      <StyledGrid columnGap={2}>
        {discountWeeks.map((week, index) => (
          <Col key={week} size={[3, 3, 1, 1]}>
            <Box>
              <Box
                bg={Color.Primary_600}
                borderRadius={1.5}
                width="64px"
                height="64px"
                display={Display.Flex}
                alignItems={AlignItems.Center}
              >
                <Text
                  size={5}
                  color={Color.White}
                  fontWeight={FontWeight.Bold}
                  textAlign={TextAlign.Center}
                >
                  {discount[index]}
                </Text>
              </Box>

              <Space size={1} />

              <Text size={2} color={Color.ColdGrey_800} textAlign={TextAlign.Center}>
                {week}
              </Text>
              <Space size={2} />
            </Box>
          </Col>
        ))}
      </StyledGrid>
    </Box>
  )
}
