import React, { useReducer, useMemo, useState, useCallback, useEffect } from 'react'
import { withOptimizely } from '@optimizely/react-sdk'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { LayoutPageWrapper, Grid, Column } from 'goustouicomponents'

import LoadingComponent from 'Loading'
import {
  ValidationModal
} from 'routes/Account/Subscription/ActiveSubscription/sections/YourSubscriptionDetails/ValidationModal'
import {
  SubscriptionContext,
} from './context'
import { SubscriptionReducer, initialSubscriptionsOptions } from './context/reducers'
import { getIsSubscriptionActive, getIsSubscriptionLoaded } from './context/selectors/subscription'
import { ActiveSubscription } from './ActiveSubscription'
import { PausedSubscription } from './PausedSubscription'
import { SubscriptionTitle } from './components/SubscriptionTitle'
import { ToastProvider } from './components/Toast'

import { useSubscriptionData } from './hooks/useSubscriptionData'
import { useCurrentUserData } from './hooks/useCurrentUserData'
import { useBoxPricesData } from './hooks/useBoxPricesData'
import { useOrdersData } from './hooks/useOrdersData'
import { useSubscriptionOptionsData } from './hooks/useSubscriptionOptionsData'
import { SubscriptionPauseReasonOSR } from './SubscriptionPauseReasonOSR'

import css from './Subscription.css'

const propTypes = {
  accessToken: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  startOnScreenRecoverySubscriptionFlow: PropTypes.func.isRequired,
  selectPauseReason: PropTypes.func.isRequired,
  isSubscriberPricingEnabled: PropTypes.bool,
  defaultSubPauseReasonOSRFlow: PropTypes.bool,
  shouldClosePauseReasonsView: PropTypes.bool.isRequired,
}

const defaultProps = {
  isSubscriberPricingEnabled: false,
  defaultSubPauseReasonOSRFlow: false,
}

const Subscription = withOptimizely(({
  accessToken,
  isMobile,
  startOnScreenRecoverySubscriptionFlow,
  isSubscriberPricingEnabled,
  defaultSubPauseReasonOSRFlow,
  shouldClosePauseReasonsView,
  selectPauseReason,
  optimizely,
}) => {
  const [showResubscribe, setShowResubscribe] = useState(false)
  const [shouldRequestSubscriptionsV2, setShouldRequestSubscriptionsV2] = useState(false)
  const [shouldRequestSlots, setShouldRequestSlots] = useState(false)
  const [state, dispatch] = useReducer(SubscriptionReducer, {
    isSubscriberPricingEnabled,
    subscriptionOptions: initialSubscriptionsOptions,
  })
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])
  const isSubscriptionActive = getIsSubscriptionActive(state)
  const isSubscriptionLoaded = getIsSubscriptionLoaded(state)
  const isWebSubscriptionPauseReasonOSREnabled = optimizely.decide('rockets_is_web_subscription_pause_osr_enabled')

  const [isSubPauseReasonOSRFlowEnabled, enableSubPauseReasonOSRFlow] = useState(defaultSubPauseReasonOSRFlow)

  useCurrentUserData(accessToken, dispatch)
  useBoxPricesData(accessToken, dispatch)
  useOrdersData(accessToken, dispatch)
  useSubscriptionData(
    accessToken,
    dispatch,
    {
      shouldRequest: shouldRequestSlots,
      setShouldRequest: setShouldRequestSlots,
    },
    {
      shouldRequest: shouldRequestSubscriptionsV2,
      setShouldRequest: setShouldRequestSubscriptionsV2,
    },
    state,
  )

  useSubscriptionOptionsData(dispatch, state)

  useEffect(() => {
    if (shouldClosePauseReasonsView) {
      enableSubPauseReasonOSRFlow(false)
    }
  }, [shouldClosePauseReasonsView])

  const handlePause = () => {
    if (isWebSubscriptionPauseReasonOSREnabled.variationKey === 't1') {
      return enableSubPauseReasonOSRFlow(true)
    }

    return startOnScreenRecoverySubscriptionFlow()
  }

  const handleContinueToPauseButton = (reason) => {
    selectPauseReason(reason)
    startOnScreenRecoverySubscriptionFlow()
  }

  const handleBackButton = useCallback(() => {
    enableSubPauseReasonOSRFlow(false)
  }, [enableSubPauseReasonOSRFlow])

  if (!isSubscriptionLoaded) {
    return (
      <div className={css.loadingState}>
        <LoadingComponent />
      </div>
    )
  }

  return (
    <SubscriptionContext.Provider value={contextValue}>
      <Helmet title="Gousto Subscription | Manage Your Subscription Settings" />
      <ToastProvider>
        <ValidationModal accessToken={accessToken} />
        <LayoutPageWrapper size="medium" padding={false} testingSelector="subscriptionSettingsPage">
          {isSubPauseReasonOSRFlowEnabled ?
            (
              <SubscriptionPauseReasonOSR
                accessToken={accessToken}
                handleBackButton={handleBackButton}
                handlePauseSubmit={handleContinueToPauseButton}
              />
            ) : (
              <>
                {showResubscribe || (
                  <Grid>
                    <Column smallScreen={12} mediumScreen={12}>
                      <SubscriptionTitle />
                    </Column>
                  </Grid>
                )}
                {
                  isSubscriptionActive ?
                    <ActiveSubscription accessToken={accessToken} isMobile={isMobile} handlePause={handlePause} />
                    :
                    <PausedSubscription accessToken={accessToken} showResubscribe={showResubscribe} setShowResubscribe={setShowResubscribe} />
                }
              </>
            )}
        </LayoutPageWrapper>
      </ToastProvider>
    </SubscriptionContext.Provider>
  )
})

Subscription.propTypes = propTypes
Subscription.defaultProps = defaultProps

export { Subscription }
