import { queueTrackingEvent } from '@library/tracking-integration'

export enum TrackingActions {
  CLICK_GET_STARTED = 'click_get_started',
  CLICK_OPEN_MENU = 'click_open_menu',
}

export enum LandingPageContentSections {
  EFFORTLESS_HOME_COOKING = 'effortlessHomeCooking',
  ENDLESS_VARIETY = 'endlessVariety',
  ENVIRONMENTAL_BENEFITS = 'environmentalBenefits',
  VALUE_YOU_CAN_TASTE = 'valueYouCanTaste',
}

type CTAClickTrackingData = {
  action: TrackingActions
  properties: {
    section: LandingPageContentSections
    url: string
  }
}

type CarouselSlideChangeTrackingData = {
  action: 'carousel_slide_change'
  properties: {
    prevSlideIndex: number
    currentSlideIndex: number
    [key: string]: unknown
  }
}

export function trackCTAClick(
  action: TrackingActions,
  landingPageContentSections: LandingPageContentSections,
) {
  queueTrackingEvent<CTAClickTrackingData>({
    action,
    properties: {
      section: landingPageContentSections,
      url: window.location.href,
    },
  })
}

export function trackCarouselSlideChange(
  prevSlideIndex: number,
  currentSlideIndex: number,
  snowplowTrackingProperties: Record<string, unknown> = {},
) {
  queueTrackingEvent<CarouselSlideChangeTrackingData>({
    action: 'carousel_slide_change',
    properties: {
      prevSlideIndex,
      currentSlideIndex,
      ...snowplowTrackingProperties,
    },
  })
}
