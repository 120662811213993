import React, { useState } from 'react'

import styled from '@emotion/styled'
import {
  AlignItems,
  Body2,
  Body3,
  BorderStyle,
  Box,
  Color,
  Space,
  Heading4,
  Heading6,
  Icon,
  Modal,
  ModalBody,
  ModalButton,
  ModalClose,
  ModalFooter,
  ModalHeader,
  RadioGroup,
  Strong,
  useModal,
} from '@gousto-internal/citrus-react'
import Immutable from 'immutable'
import { useDispatch, useSelector } from 'react-redux'

import { ShippingAddress } from '@library/api-user'
import { queueTrackingEvent } from '@library/tracking-integration'

import {
  basketChosenAddressChange,
  basketAddressChange,
  basketPostcodeChangePure,
} from 'actions/basket'
import { DELIVERY_ADDRESS_MODAL_NAME } from 'routes/DeliveryDetails/constants'
import { getChosenAddress } from 'selectors/basket'
import { getUserShippingAddresses } from 'selectors/user'

const StyledRadioContainer = styled.div`
  label {
    align-items: flex-start;
  }
`

const InfoBox = () => (
  <Box
    display="flex"
    alignItems={AlignItems.FlexStart}
    bg={Color.Informative_50}
    paddingH={3}
    paddingV={4}
    borderWidth={1}
    borderColor={Color.Informative_200}
    borderRadius={1}
    borderStyle={BorderStyle.Solid}
  >
    <Icon name="info" />
    <Box paddingH={2}>
      <Heading6>Want to add a new address?</Heading6>
      <Body3>
        Head to the <Strong color={Color.Secondary_400}>Delivery info</Strong> section of your
        account details page.
      </Body3>
    </Box>
  </Box>
)

const DeliveryAddressModal = () => {
  const { closeCurrentModal } = useModal()
  const dispatch = useDispatch()
  const deliveryAddresses: Immutable.List<Immutable.Map<keyof ShippingAddress, unknown>> =
    useSelector(getUserShippingAddresses)
  const chosenAddress = useSelector(getChosenAddress)
  const [addressId, setAddressId] = useState(chosenAddress.get('id'))

  const getRadioLabel = (address: ShippingAddress) => {
    const { id, name, line1, line2, line3, town, postcode } = address

    return (
      <Box paddingH={2}>
        <Body2 fontWeight={700} color={id === addressId ? Color.Secondary_400 : Color.ColdGrey_800}>
          {name}
        </Body2>
        <Body3>{line1}</Body3>
        <Body3>{line2}</Body3>
        <Body3>{line3}</Body3>
        <Body3>
          {town}, {postcode}
        </Body3>
      </Box>
    )
  }

  const getRadioOptions = () =>
    deliveryAddresses.toJS().map((address: ShippingAddress) => ({
      value: address?.id,
      label: getRadioLabel(address),
    }))

  const changeAddress = () => {
    const selectedAddress = deliveryAddresses
      .filter((address) => address?.get('id') === addressId)
      .first()
    dispatch(basketPostcodeChangePure(selectedAddress.get('postcode')))
    dispatch(basketAddressChange(selectedAddress))
    dispatch(basketChosenAddressChange(selectedAddress))
    closeCurrentModal()

    queueTrackingEvent<{
      action: 'confirm_delivery_address'
    }>({
      action: 'confirm_delivery_address',
    })
  }

  return (
    <Modal name={DELIVERY_ADDRESS_MODAL_NAME} onRequestClose={() => closeCurrentModal()}>
      <ModalClose onClose={() => closeCurrentModal()} />
      <ModalHeader>
        <Heading4>Edit delivery address</Heading4>
      </ModalHeader>
      <ModalBody>
        <StyledRadioContainer>
          <RadioGroup
            value={addressId}
            options={getRadioOptions()}
            onChange={(event) => setAddressId(event.target.value)}
            outline
          />
        </StyledRadioContainer>
        <Space size={3} />
        <InfoBox />
      </ModalBody>
      <ModalFooter>
        <ModalButton
          aria-label="Confirm delivery address"
          width="100%"
          onClick={() => changeAddress()}
        >
          Confirm delivery address
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export { DeliveryAddressModal }
