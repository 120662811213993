import {
  getBillingAddress,
  getIsBillingAddressDifferent,
  isApplePayPayment,
  isCardPayment,
} from 'routes/Checkout/checkoutPaymentSelectors'
import {
  getApplePayDeliveryAddress,
  getApplePayPersonalInfo,
  getDeliveryAddress,
  getDeliveryInstruction,
  getEmail,
  getFormattedPhoneNumber,
  getIsCompetitionsAndSurveysEnabled,
  getIsEmailMarketingEnabled,
  getName,
  getPasswordValue,
  getPhone,
} from 'routes/Checkout/checkoutSelectors'
import { getAddress } from 'routes/Checkout/checkoutUtils'
import { CheckoutStore } from 'routes/Checkout/models/CheckoutStore'
import { signupConfig } from 'routes/Signup/signupConfig'
import { getPreviewOrderId, getPromoCode } from 'selectors/basket'
import { getSessionId } from 'selectors/cookies'
import type { DeliveryDetailsStore } from 'selectors/deliveryDetails'
import {
  getDeliveryDetailsInstructions,
  getDeliveryDetailsInstructionsCustom,
} from 'selectors/deliveryDetails'
import { getIsGoustoOnDemandEnabled } from 'selectors/features'

type CheckoutDelivery = CheckoutStore & DeliveryDetailsStore

export const buildSignupRequestData = (state: CheckoutDelivery) => {
  const { basket, promoAgeVerified, checkout } = state

  const isApplePay = isApplePayPayment(state)
  const applePayPersonalInfo = getApplePayPersonalInfo(state)

  const orderId = getPreviewOrderId(state)
  const promoCode = getPromoCode(state) || ''
  const sessionId = getSessionId()

  const tariffId = basket.get('tariffId', '')
  const phoneNumber = getFormattedPhoneNumber(
    isApplePay ? applePayPersonalInfo.phoneNumber : getPhone(state),
  )
  const email = getEmail(state)
  const personalInfo = getName(state)
  const firstName = isApplePay ? applePayPersonalInfo.firstName : personalInfo.firstName.trim()
  const lastName = isApplePay ? applePayPersonalInfo.lastName : personalInfo.lastName.trim()
  const password = getPasswordValue(state)
  const ageVerified = Number(promoAgeVerified || false)
  const allowEmail = Number(getIsEmailMarketingEnabled(state) || false)
  const allowThirdParty = Number(getIsCompetitionsAndSurveysEnabled(state) || false)
  const goustoRef = checkout.get('goustoRef')
  const isBillingAddressDifferent = getIsBillingAddressDifferent(state) || false

  const { deliveryInstruction, deliveryInstructionCustom } = getDeliveryInstruction(state)
  const deliveryInstructions = isApplePay
    ? getDeliveryDetailsInstructionsCustom(state) || getDeliveryDetailsInstructions(state)
    : deliveryInstructionCustom || deliveryInstruction
  const addressFields = getDeliveryAddress(state)
  const billingAddressFields = getBillingAddress(state)
  const deliveryAddress = isApplePay ? getApplePayDeliveryAddress(state) : getAddress(addressFields)

  const billingAddress =
    isCardPayment(state) && isBillingAddressDifferent
      ? getAddress(billingAddressFields)
      : deliveryAddress

  const deliverySlotId = basket.get('slotId')
  const boxId = basket.get('boxId')

  const isGoustoOnDemandEnabled = getIsGoustoOnDemandEnabled(state)
  const subscription = isGoustoOnDemandEnabled
    ? { paused: Number(isGoustoOnDemandEnabled || false) }
    : {}

  return {
    order_id: orderId,
    promocode: promoCode,
    session_id: sessionId,
    customer: {
      tariff_id: tariffId,
      phone_number: phoneNumber,
      email,
      name_first: firstName,
      name_last: lastName,
      promo_code: promoCode,
      password,
      age_verified: ageVerified,
      marketing_do_allow_email: allowEmail,
      marketing_do_allow_thirdparty: allowThirdParty,
      gousto_ref: goustoRef,
    },
    addresses: {
      shipping_address: {
        type: signupConfig.address_types.shipping,
        delivery_instructions: deliveryInstructions,
        ...deliveryAddress,
      },
      billing_address: {
        type: signupConfig.address_types.billing,
        ...billingAddress,
      },
    },
    subscription: {
      interval_id: 1,
      delivery_slot_id: deliverySlotId,
      box_id: boxId,
      ...subscription,
    },
    decoupled: {
      payment: 1,
    },
  }
}
