import React from 'react'

import { Body2, Bold, Box, Color, Display, Heading6 } from '@gousto-internal/citrus-react'

type InformationComponentProps = {
  title: string
  body: string
}
export const InformationComponent = (props: InformationComponentProps) => {
  const { title, body } = props

  return (
    <Box
      display={Display.Block}
      bg={Color.Informative_50}
      borderColor={Color.Informative_200}
      width="100%"
      paddingV={3}
      paddingH={3}
      borderRadius={1.5}
      data-testid="info-panel"
    >
      <Heading6>
        <Bold>{title}</Bold>
      </Heading6>
      <Body2>{body}</Body2>
    </Box>
  )
}
