import React, { useCallback, useState } from 'react'

import { Button } from '@gousto-internal/citrus-react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { basketSlotChange, basketIsShowDetailsViewFirstChange } from 'actions/basket'
import { boxSummaryVisibilityChange, trackDeliveryOptionsShown } from 'actions/boxSummary'
import config from 'config/basket'
import { useIsSkipTheWizardEnabled } from 'hooks/useIsSkipTheWizardEnabled'
import { checkoutBasket } from 'routes/Menu/actions/menuCheckoutClick'
import { CheckoutCounter } from 'routes/Menu/components/BoxSummary/Banner/CheckoutCounter/CheckoutCounter'
import { useBasket, useNumRecipesOverBasketLimit } from 'routes/Menu/domains/basket'
import { usePricing } from 'routes/Menu/domains/pricing'
import { getHasProspectNotSeenWizard } from 'routes/Menu/selectors/menu'
import { getBasketPostcode, getBasketRecipes, getBasketSlotId } from 'selectors/basket'
import {
  getDeliveryDetailsInstructions,
  getDeliveryDetailsInstructionsCustom,
} from 'selectors/deliveryDetails'
import { getMenuRecipeIds, getMenuService } from 'selectors/root'
import { createGetActionTypeIsPending, createGetErrorForActionType } from 'selectors/status'
import { basketSum, okRecipes } from 'utils/basket'

import css from './CheckoutButton.css'

type Props = {
  view: string
  section: string
  hideCounter?: boolean
  isFullWidth?: boolean
}

const useCheckoutButtonIsPending = () => {
  const orderSaveError = useSelector(createGetErrorForActionType(actionTypes.ORDER_SAVE))

  const { isPending: pricingPending } = usePricing()

  const checkoutPending = useSelector(createGetActionTypeIsPending(actionTypes.BASKET_CHECKOUT))
  const basketPreviewOrderChangePending = useSelector(
    createGetActionTypeIsPending('BASKET_PREVIEW_ORDER_CHANGE'),
  )
  const orderSavePending = useSelector(createGetActionTypeIsPending('ORDER_SAVE'))
  const loadingOrderPending = useSelector(createGetActionTypeIsPending(actionTypes.LOADING_ORDER))
  const menuFetchData = useSelector(createGetActionTypeIsPending(actionTypes.MENU_FETCH_DATA))

  const isPending =
    (checkoutPending ||
      pricingPending ||
      basketPreviewOrderChangePending ||
      orderSavePending ||
      loadingOrderPending ||
      menuFetchData) &&
    orderSaveError === null

  return isPending
}

export const CheckoutButton = ({
  view,
  section,
  hideCounter = false,
  isFullWidth = false,
}: Props) => {
  const numRecipesOverBasketLimit = useNumRecipesOverBasketLimit()
  const isSkipTheWizardEnabled = useIsSkipTheWizardEnabled()
  const isPending = useCheckoutButtonIsPending()
  const { numPortions, removeRecipe } = useBasket()

  const orderSaveError = useSelector(createGetErrorForActionType(actionTypes.ORDER_SAVE))

  const recipes = useSelector(getBasketRecipes)
  const menuRecipes = useSelector(getMenuRecipeIds)
  const menuService = useSelector(getMenuService)
  const hasProspectNotSeenWizard = useSelector(getHasProspectNotSeenWizard)
  const hasBasketPostCode = useSelector(getBasketPostcode)
  const deliveryInstructions = useSelector(getDeliveryDetailsInstructions)
  const deliveryInstructionsCustom = useSelector(getDeliveryDetailsInstructionsCustom)
  const hasSlotId = useSelector(getBasketSlotId)

  const hasDeliveryInstructions = !!deliveryInstructions || !!deliveryInstructionsCustom
  const isNumRecipesOverBasketLimit = !!numRecipesOverBasketLimit

  const hasProspectNotSeenWizardInvalid =
    (hasProspectNotSeenWizard || isSkipTheWizardEnabled) &&
    (!hasBasketPostCode || !hasDeliveryInstructions)

  const numRecipes = basketSum(okRecipes(recipes, menuRecipes, menuService, numPortions))

  const isDisabled =
    isPending ||
    numRecipes < config.minRecipesNum ||
    orderSaveError !== null ||
    isNumRecipesOverBasketLimit ||
    !hasSlotId

  const { pricing } = usePricing()
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      if (hasProspectNotSeenWizardInvalid) {
        dispatch(basketSlotChange())
        dispatch(basketIsShowDetailsViewFirstChange(false))
        dispatch(boxSummaryVisibilityChange(true, () => {}))
        dispatch(trackDeliveryOptionsShown())
      } else {
        dispatch(checkoutBasket({ section, view, pricing, removeRecipe }))
      }
    },
    [dispatch, section, view, pricing, removeRecipe, hasProspectNotSeenWizardInvalid],
  )

  // CheckoutCounter should change background color when the button is hovered.
  // While a css-only solution is possible in theory (`.button:hover
  // .counter`), we cannot refer to class names defined in other css module
  // files, or via CSS-in-JS.
  const [isButtonHovered, setIsButtonHovered] = useState(false)

  return (
    <div
      className={classNames(css.buttonContainer, { [css.isFullWidth]: isFullWidth })}
      data-testing="boxSummaryButton"
      onMouseEnter={() => setIsButtonHovered(true)}
      onMouseLeave={() => setIsButtonHovered(false)}
    >
      <Button
        height={48}
        onClick={handleClick}
        disabled={isDisabled}
        width={isFullWidth ? '100%' : undefined}
      >
        <span className={css.checkoutLabel}>Checkout</span>
        {hideCounter ? null : (
          <CheckoutCounter
            isDisabled={isDisabled}
            isButtonHovered={isButtonHovered}
            numRecipes={numRecipes}
          />
        )}
      </Button>
    </div>
  )
}
