import React from 'react'

import { Alert, Button } from 'goustouicomponents'
import PropTypes from 'prop-types'

import css from './OrderCancelButton.css'

class OrderCancelButton extends React.PureComponent {
  handleCancelBox = () => {
    const { orderState, orderId, deliveryDayId, orderCancelStart, deliveryDay } = this.props
    if (orderState !== 'scheduled') {
      orderCancelStart(orderId, deliveryDayId, deliveryDay, 'pending')
    } else {
      orderCancelStart(null, deliveryDayId, deliveryDay, 'projected')
    }
  }

  render() {
    const {
      contentPending,
      didCancelProjectedError,
      deliveryDayId,
      orderId,
      osrDeliveryDayId,
      osrOrderId,
    } = this.props

    let pending = false
    if (contentPending && deliveryDayId === osrDeliveryDayId) pending = true
    if (contentPending && orderId === osrOrderId) pending = true

    return (
      <div className={css.button}>
        {didCancelProjectedError ? (
          <Alert type="danger">
            Whoops, there was a problem cancelling this order, please try again.
          </Alert>
        ) : null}
        <Button
          color="negative"
          onClick={this.handleCancelBox}
          className={css.cancelButton}
          pending={pending}
          data-testing="cancelButton"
        >
          Cancel delivery
        </Button>
      </div>
    )
  }
}

OrderCancelButton.propTypes = {
  orderId: PropTypes.string,
  deliveryDayId: PropTypes.string,
  osrOrderId: PropTypes.string,
  osrDeliveryDayId: PropTypes.string,
  orderState: PropTypes.string,
  didCancelProjectedError: PropTypes.bool,
  orderCancelStart: PropTypes.func,
  deliveryDay: PropTypes.string,
  contentPending: PropTypes.bool,
}

OrderCancelButton.defaultProps = {
  orderId: '',
  deliveryDayId: '',
  osrOrderId: '',
  osrDeliveryDayId: '',
  orderState: '',
  didCancelProjectedError: false,
  orderCancelStart: () => {},
  deliveryDay: '',
  contentPending: false,
}

export default OrderCancelButton
