import { connect } from 'react-redux'

import { selectOrderIssue } from 'actions/getHelp'
import { getUserId } from 'selectors/user'
import { getOrderId } from '../selectors/selectors'

import { OrderIssue } from './OrderIssue'

const mapStateToProps = (state) => ({
  content: {
    title: 'Get help with your box',
    body: 'What part of your order had an issue?',
    ingredientsItem: 'Ingredients (missing, damaged, wrong)',
    recipeCardItem: 'Recipe cards',
    deliveryItem: 'Delivery',
    otherItem: 'Other',
  },
  orderId: getOrderId(state),
  userId: getUserId(state),
})

const OrderIssueContainer = connect(mapStateToProps, {
  selectOrderIssue,
})(OrderIssue)

export {
  OrderIssueContainer
}
