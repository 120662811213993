import React from 'react'

import {
  AlignItems,
  Body1,
  Box,
  Color,
  Display,
  Heading5,
  Icon,
  IconVariant,
  JustifyContent,
  Link,
  Space,
  Text,
  useModal,
} from '@gousto-internal/citrus-react'
import Immutable from 'immutable'
import { useSelector } from 'react-redux'

import { ShippingAddress } from '@library/api-user'
import { queueTrackingEvent } from '@library/tracking-integration'

import { client as clientRoutes } from 'config/routes'
import { DELIVERY_ADDRESS_MODAL_NAME } from 'routes/DeliveryDetails/constants'
import { getChosenAddress } from 'selectors/basket'

type DeliveryAddressProps = {
  address?: {
    line1: string
    line2: string
    line3: string
    name: string
    postcode: string
    town: string
  }
}

const DeliveryAddress = ({ address }: DeliveryAddressProps) => {
  const { openModal } = useModal()
  const chosenAddress: Immutable.Map<keyof ShippingAddress, unknown> = useSelector(getChosenAddress)
  const { line1, line2, line3, name, postcode, town } = address ?? chosenAddress.toJS()

  const onClick = () => {
    openModal(DELIVERY_ADDRESS_MODAL_NAME)

    queueTrackingEvent<{
      action: 'edit_delivery_address'
    }>({
      action: 'edit_delivery_address',
    })
  }

  return (
    <Box paddingBottom={[2, 0]}>
      <Box display="flex" justifyContent={JustifyContent.SpaceBetween} width="100%">
        <Heading5>Delivery address</Heading5>
        {!address && (
          <Box
            display={Display.Flex}
            alignItems={AlignItems.Center}
            role="button"
            aria-label="Edit delivery address"
            onClick={onClick}
            data-testid="address-edit-button"
          >
            <Icon name="edit" variant={IconVariant.Actionable} size={5} />
            <Space size={1} direction="horizontal" />
            <Text size={1} color={Color.Secondary_400}>
              Edit
            </Text>
          </Box>
        )}
      </Box>
      <Body1 fontWeight={700}>{name}</Body1>
      <Body1>{line1}</Body1>
      <Body1>{line2}</Body1>
      <Body1>{line3}</Body1>
      <Body1>
        {town}, {postcode}
      </Body1>
      {address && (
        <Box paddingV={2} width={['100%', '70%']}>
          <Text size={1}>
            Want to change the address for this order? Visit{' '}
            <Link href={clientRoutes.myDeliveries} size={1} style={{ boxShadow: 'none' }}>
              &apos;Upcoming Deliveries&apos;
            </Link>
            .
          </Text>
        </Box>
      )}
    </Box>
  )
}

export { DeliveryAddress }
