import React from 'react'

import {
  Box,
  Color,
  BorderStyle,
  Icon,
  Text,
  Display,
  Space,
  IconVariant,
} from '@gousto-internal/citrus-react'

export const BoxSizeAlert = () => (
  <Box
    bg={Color.Warning_50}
    borderColor={Color.Warning_100}
    borderRadius={1}
    borderStyle={BorderStyle.Solid}
    borderWidth={0.5}
    data-testid="box-size-alert"
    display={Display.Flex}
    paddingH={2}
    paddingV={2}
  >
    <Box paddingRight={2}>
      <Icon name="warning" variant={IconVariant.Error} />
    </Box>
    <Box>
      <Text fontWeight={700} size={3}>
        You have an existing order on this date
      </Text>
      <Space size={1} />
      <Text size={3}>Edit your box size for this order on the next step.</Text>
    </Box>
  </Box>
)
