import React from 'react'

import { CSSObject } from '@emotion/react'
import {
  AlignItems,
  Body1,
  Box,
  Color,
  Display,
  FlexDirection,
  Heading4,
  JustifyContent,
  ResponsiveValue,
  TextAlign,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

import eggsSmall from '../../assets/375_eggs.svg'
import eggsMedium from '../../assets/640_eggs.svg'
import eggsLarge from '../../assets/768_eggs.svg'
import eggsXLarge from '../../assets/1024_eggs.svg'
import { BoxFlexibilityAwarenessTexts } from './data'

const responsiveBackground = (backgroundColor: string) =>
  [
    `${backgroundColor} no-repeat center center / cover url(${eggsSmall})`,
    `${backgroundColor} no-repeat center center / cover url(${eggsMedium})`,
    `${backgroundColor} no-repeat center center / cover url(${eggsLarge})`,
    `${backgroundColor} no-repeat center center / cover url(${eggsXLarge})`,
  ] as ResponsiveValue<string>

const Container = withCitrusTheme(Box, (theme) => ({
  ...(theme.responsiveStyle(responsiveBackground(theme.color(Color.ColdGrey_900) as string), [
    'background',
  ]) as CSSObject),
}))

export function BoxFlexibilityAwareness() {
  const { title: titleText, subTitle: subTitleText } = BoxFlexibilityAwarenessTexts

  return (
    <Container
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
      paddingH={8}
      paddingV={8}
      data-testid="box-flexibility-awareness"
    >
      <Heading4 color={Color.White} size={5} textAlign={TextAlign.Center}>
        {titleText}
      </Heading4>
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Center}
        maxWidth={['100%', '70%', '65%', '100%']}
      >
        <Body1 color={Color.ColdGrey_50} size={2} textAlign={TextAlign.Center}>
          {subTitleText}
        </Body1>
      </Box>
    </Container>
  )
}
