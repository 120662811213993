import moment from 'moment'
import { push } from 'react-router-redux'
import { Dispatch } from 'redux'

import { ThunkExperiments } from '@library/experimentation'

import * as trackingKeys from 'actions/trackingKeys'
import { fetchDeliveryDays } from 'apis/deliveries'
import fetchData from 'routes/Menu/fetchData'
import { getAuthUserId, getIsAuthenticated } from 'selectors/auth'
import { getBasketRecipes } from 'selectors/basket'
import { getDeliveryDetailsInstructions } from 'selectors/deliveryDetails'
import { getUsersOrdersDaySlotLeadTimeIds } from 'selectors/user'
import Cookies from 'utils/GoustoCookies'
import { okRecipes, basketSum } from 'utils/basket'
import {
  getAvailableDeliveryDays,
  getLandingDay,
  transformDaySlotLeadTimesToMockSlots,
  getDeliveryTariffId,
  getCutoffForDateAndSlot,
  mapTwoDaysDelivery10PMCutOffKeyToValue,
} from 'utils/deliveries'
import logger from 'utils/logger'

import { RemoveRecipeFn } from '../routes/Menu/domains/basket'
import { actionTypes } from './actionTypes'
import {
  basketAddressChange,
  basketDateChange,
  basketPostcodeChange,
  basketSlotChange,
} from './basket'
import status from './status'

type State = any
type GetState = () => State

export const basketDeliveryDaysReceive = (days: any) => ({
  type: actionTypes.BOXSUMMARY_DELIVERY_DAYS_RECEIVE,
  days,
})

export function boxSummaryDeliverySlotChosen({ date, slotId }: { date: any; slotId: any }) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const cutOffDate = getCutoffForDateAndSlot(
      date,
      slotId,
      getState().boxSummaryDeliveryDays,
    )?.substr(0, 10)
    dispatch(basketDateChange(date))
    dispatch(basketSlotChange(slotId))
    dispatch(
      fetchData({ query: {}, params: {} }, true, undefined, {
        addRecipe: () => {},
        date: cutOffDate,
      }),
    )
  }
}

export function boxSummaryVisibilityChange(
  show: boolean,
  removeRecipe: RemoveRecipeFn,
  totalPrice?: string,
) {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const state = getState()
    const basketRecipes = state.basket.get('recipes')
    const amountOfRecipesInBasket = basketSum(basketRecipes)

    if (show) {
      const type = trackingKeys.clickViewBasket
      dispatch({
        type,
        trackingData: {
          actionType: type,
          recipes_added: amountOfRecipesInBasket,
        },
      })
    }

    dispatch({
      type: actionTypes.BOXSUMMARY_VISIBILITY_CHANGE,
      show,
      trackingData: {
        actionType: trackingKeys.changeBoxSummaryVisibility,
        show,
        recipes_added: amountOfRecipesInBasket,
        totalPrice,
      },
    })

    if (!show) {
      const okRecipeIds = okRecipes(
        basketRecipes,
        state.menuRecipes,
        state.menuService,
        state.basket.get('numPortions'),
      )
      basketRecipes
        .filter((amount: any, recipeId: string) => !okRecipeIds.has(recipeId))
        .forEach((amount: any, recipeId: string) => {
          for (let x = 0; x < amount; x++) {
            removeRecipe(recipeId)
          }
        })
    }
  }
}

export function boxSummaryDeliveryDaysLoad(cutoffDatetimeFrom?: any, cutoffDatetimeUntil?: any) {
  return async (dispatch: Dispatch<any>, getState: GetState, { experiments }: ThunkExperiments) => {
    const state = getState()
    let hasAcceptedAnalytics = false
    try {
      const consentCookieString = Cookies.get('v1_gousto_cookie_consent')
      const consentCookie = consentCookieString ? JSON.parse(consentCookieString) : {}
      hasAcceptedAnalytics = consentCookie?.categories?.includes('ANALYTICS')
    } catch (error) {
      if (error instanceof Error) {
        // eslint-disable-next-line prettier/prettier
        ;(logger as any).error(error.message)
      }
    }
    const isAuthenticated = getIsAuthenticated(state)
    const { auth, basket, menuCutoffUntil, user } = state
    // [TG-8940] We only allocate users to the experiment if they have consented
    // to the Analytics cookies and are not authenticated
    const { enabled, variationKey } =
      hasAcceptedAnalytics && !isAuthenticated
        ? experiments.decide('beetroots_ofx_2dd_10pm_cutoff_bis')
        : { enabled: false, variationKey: undefined }

    dispatch(status.error(actionTypes.BOXSUMMARY_DELIVERY_DAYS_RECEIVE, false))

    const postcode = basket.get('postcode') || null
    const laterCutoffExperiment =
      enabled && ['t1', 't2'].includes(variationKey as string)
        ? mapTwoDaysDelivery10PMCutOffKeyToValue(variationKey)
        : undefined

    /**
     * There could be few sources for `menuCutoffUntil` date:
     *  * the highest priority is to the date provided by the caller of the function
     *    as it has more context and could have different data (e.g. Wizard, delivery steps)
     *  * however if the caller didn't provide one it sources the value from the Redux store;
     *  ** `menuCutoffUntil` is value calculated based on data sourced from `/active-periods` endpoint
     *      which was designed to expose only generic information for currently active periods.
     * There reason why the logic still relies on `menuCutoffUntil` as a last resort: potentially there could be
     * rare condition where the `/active-periods` did not respond but the components with "Delivery slots" proceed
     * to be rendered. When it occurs the customer would see only one week of delivery slots instead of three.
     */
    const cutoffUntil = cutoffDatetimeUntil
      ? moment.utc(cutoffDatetimeUntil).endOf('day').toISOString()
      : menuCutoffUntil

    try {
      const accessToken = auth.get('accessToken')
      const cutoffDatetimeFromFormatted = moment
        .utc(cutoffDatetimeFrom)
        .startOf('day')
        .toISOString()
      const deliveryTariffId = getDeliveryTariffId(user)
      const userId = getAuthUserId(state)
      let { data: days } = await fetchDeliveryDays(
        accessToken,
        cutoffDatetimeFromFormatted,
        cutoffUntil,
        deliveryTariffId,
        postcode,
        userId,
        laterCutoffExperiment,
      )

      days = transformDaySlotLeadTimesToMockSlots(days)

      const availableDeliveryDays = getAvailableDeliveryDays(
        days,
        cutoffDatetimeFrom,
        getUsersOrdersDaySlotLeadTimeIds(state),
      )

      dispatch(basketDeliveryDaysReceive(availableDeliveryDays))
    } catch (err: any) {
      if (err.message !== 'do-not-deliver') {
        // eslint-disable-next-line prettier/prettier
        ;(logger as any).error(err)
      }

      dispatch(status.error(actionTypes.BOXSUMMARY_DELIVERY_DAYS_RECEIVE, err.message))
    }
  }
}

export const boxSummaryNext =
  (removeRecipe: RemoveRecipeFn) => (dispatch: Dispatch<any>, getState: GetState) => {
    const state = getState()
    const landing = getLandingDay(state, { useCurrentSlot: true })

    const tempDate = state.temp.get('date', landing.date)
    const tempSlotId = state.temp.get('slotId', landing.slotId)
    const tempOrderId = state.temp.get('orderId', landing.orderId)

    const basketPostcode = state.basket.get('postcode')

    if (basketPostcode && !state.error.get(actionTypes.BOXSUMMARY_DELIVERY_DAYS_RECEIVE)) {
      if (tempOrderId) {
        dispatch(push(`/menu/${tempOrderId}`))
        dispatch(boxSummaryVisibilityChange(false, removeRecipe))
      } else {
        dispatch(
          boxSummaryDeliverySlotChosen({
            date: tempDate,
            slotId: tempSlotId,
          }),
        )
        if (getBasketRecipes(state).size === 0) {
          dispatch(boxSummaryVisibilityChange(false, removeRecipe))
        }
      }
    } else {
      const tempPostcode = state.temp.get('postcode', '')
      let shippingDefault
      if (state.user.get('shippingAddresses')) {
        shippingDefault = state.user
          .get('shippingAddresses')
          .filter((address: any) => address.get('shippingDefault'))
          .first()
      }

      const chosenAddress = state.basket.get('chosenAddress') || shippingDefault

      if (chosenAddress) {
        dispatch(basketAddressChange(chosenAddress))
        dispatch(basketPostcodeChange(chosenAddress.get('postcode')))
      } else if (tempPostcode && tempPostcode.trim() !== '') {
        const postcode = state.temp.get('postcode')
        dispatch(basketPostcodeChange(postcode))
      }
    }
  }

export const trackingUnavailableRecipeList = (unavailableRecipeList: any) => ({
  type: actionTypes.TRACKING_UNAVAILABLE_RECIPE_LIST,
  trackingData: {
    actionType: trackingKeys.unavailableRecipeList,
    unavailableRecipeList: unavailableRecipeList.keySeq().toArray(),
  },
})

export const trackDeliveryInstructionSelection =
  () => (dispatch: Dispatch<any>, getState: GetState) => {
    const deliveryInstruction = getDeliveryDetailsInstructions(getState())

    dispatch({
      type: actionTypes.TRACKING,
      trackingData: {
        actionType: trackingKeys.deliveryInstructionDetailsClick,
        selection: deliveryInstruction,
      },
    })
  }

export const trackDeliveryOptionsShown = () => ({
  type: actionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.deliveryOptionsPostCodeShown,
    shown: true,
  },
})

export const trackRecipeChangeWarning = (tempDate: string) => ({
  type: actionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.recipeChangeWarning,
    tempDate,
  },
})

export const trackBoxSizeSelectionChange = (boxSize: number, view: string) => ({
  type: actionTypes.TRACKING,
  trackingData: {
    actionType: trackingKeys.radioBoxSizeSelected,
    view,
    boxSize,
  },
})
