import { useDispatch, useSelector } from 'react-redux'

import { homeGetStarted } from 'actions/home'
import { useGetCtaProps } from 'routes/Home/Cta'
import { useGetPromoBannerDispatchedActions } from 'routes/Home/PromoBanner/promoBannerHooks'
import { getRecipesFromAllRecipesCollection } from 'routes/Home/getRecipesFromAllRecipesCollection'

export const useHandleSignupCTAClick = () => {
  const dispatch = useDispatch()
  const { ctaUri } = useGetCtaProps()

  const handleSignupCTAClick = () => {
    dispatch(homeGetStarted(ctaUri))
  }

  return handleSignupCTAClick
}

export const useGetAllRecipes = () => {
  const allRecipes = useSelector(getRecipesFromAllRecipesCollection)
  const recipes = allRecipes.toJS()

  return recipes
}

export const useApplyDiscount = () => {
  const { dispatchApplyPromoCodeAndShowModal, dispatchRedirect, dispatchTrackUTMAndPromoCode } =
    useGetPromoBannerDispatchedActions()

  const applyDiscount = () => {
    dispatchApplyPromoCodeAndShowModal()
    dispatchRedirect()
    dispatchTrackUTMAndPromoCode()
  }

  return applyDiscount
}
