import { composeFetch } from '@library/http'
import {
  composeRequest,
  addPath,
  setServiceUrl,
  setMethod,
  setQueryParams,
  setHeaders,
} from '@library/http/requests'
import { Menu } from './types'

type MenuRequestParams = {
  accessToken?: string
  date?: string
  deliveryDate?: string
  numPortions: number
  sessionId: string
  userId?: string
}

const menuApiMenuParse = async (response: Response): Promise<Menu> => {
  const body = await response.text()
  const parsedBody = JSON.parse(body)

  if (!response.ok) {
    throw new Error(parsedBody.message)
  }

  return parsedBody as Menu
}

const menuApiMenuRequest = composeRequest(
  setServiceUrl('menu', 3),
  addPath(() => 'menus'),
  setQueryParams(({ date, deliveryDate, numPortions, userId }: MenuRequestParams) => ({
    ...(date && { date }),
    ...(deliveryDate && { delivery_date: deliveryDate }),
    num_portions: numPortions.toString(),
    ...(userId && { user_id: userId }),
  })),
  setHeaders(({ accessToken, sessionId }) => {
    return {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...(sessionId ? { 'x-gousto-device-id': sessionId } : {}),
    }
  }),
  setMethod('GET'),
)

export const menuFetcher = composeFetch(menuApiMenuRequest, menuApiMenuParse)
