import Decimal from 'decimal.js'

import { boxPrices } from './config'

const PROMOTION_DURATION = 2 // months
const ORDER_FREQUENCY = 4 // per month

/**
 * Generates a box type string based on size and number of portions.
 * @param boxSize - The size of the box (number of recipes in a box).
 * @param numPortions - The number of portions.
 * @returns The box type string.
 */
function boxType(boxSize: number, numPortions: number): string {
  return `${boxSize}x${numPortions}`
}

/**
 * Calculates the discounted price based on the original price and discount percentage.
 * @param price - The original price.
 * @param discountPercentage - The discount percentage.
 * @returns The discounted price as a Decimal.
 */
function calculateDiscountedPrice(price: number, discountPercentage: number): Decimal {
  return new Decimal(price).minus(new Decimal(price).times(discountPercentage).div(100))
}

/**
 * Calculates the price per portion based on the discounted price, box size, and number of portions.
 * @param discountedPrice - The discounted price.
 * @param numPortions - The number of portions.
 * @param boxSize - The size of the box.
 * @returns The price per portion as a Decimal.
 */
function calculatePricePerPortion(
  discountedPrice: Decimal,
  numPortions: number,
  boxSize: number,
): Decimal {
  return discountedPrice.div(numPortions * boxSize)
}

/**
 * Calculates the total savings over a promotional period.
 * @param normalBoxPrice - The normal price of the box.
 * @param initialDiscountedBoxPrice - The initial promotional discount price of the box.
 * @param recurringDiscountBoxPrice - The recurring promotional discount price of the box.
 * @param promotionDuration - The duration of the promotion in months.
 * @param orderFrequency - The frequency of orders per month.
 * @returns The total savings as a Decimal.
 */
function calculateTotalSavings(
  normalBoxPrice: number,
  initialDiscountedBoxPrice: Decimal,
  recurringDiscountBoxPrice: Decimal,
  promotionDuration = PROMOTION_DURATION,
  orderFrequency = ORDER_FREQUENCY,
): Decimal {
  const numberOfOrders = promotionDuration * orderFrequency
  const totalCostNoDiscount = new Decimal(normalBoxPrice).times(numberOfOrders)
  const totalRecurringDiscountCost = recurringDiscountBoxPrice.times(numberOfOrders - 1)
  const totalSavings = totalCostNoDiscount.minus(
    initialDiscountedBoxPrice.plus(totalRecurringDiscountCost),
  )

  return totalSavings
}

/**
 * Retrieves the prices and savings for a given box size and number of portions.
 * @param boxSize - The size of the box (number of recipes in a box).
 * @param numPortions - The number of portions.
 * @returns An object containing the discounted first box price, first box price per portion, and total promotional saving.
 */
export function getPromotionalPricing(boxSize: number, numPortions: number) {
  const selectedBox = boxType(boxSize, numPortions)

  const box = boxPrices[selectedBox]
  if (!box) {
    throw new Error(`Box type ${selectedBox} not found`)
  }

  const fullBoxPrice = box.price

  const firstBoxDiscountPrice = calculateDiscountedPrice(fullBoxPrice, box.firstBoxDiscount)
  const firstBoxDiscountPricePerPortion = calculatePricePerPortion(
    firstBoxDiscountPrice,
    boxSize,
    numPortions,
  )

  const recurringDiscountBoxPrice = calculateDiscountedPrice(fullBoxPrice, box.secondBoxDiscount)
  const totalPromotionalSavings = calculateTotalSavings(
    fullBoxPrice,
    firstBoxDiscountPrice,
    recurringDiscountBoxPrice,
  )

  return {
    firstBoxPriceWithDiscount: firstBoxDiscountPrice.toFixed(2),
    firstBoxPerPortionPriceWithDiscount: firstBoxDiscountPricePerPortion.toFixed(2),
    totalSaving: totalPromotionalSavings.toFixed(2),
  }
}
