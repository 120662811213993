import React from 'react'

import { Link } from '@gousto-internal/citrus-react'
import { Button, Segment } from 'goustouicomponents'
import Immutable from 'immutable'
import moment from 'moment'

import { client as clientRoutes } from 'config/routes'
import { getSlotTimes } from 'utils/deliveries'

import css from '../Details.css'

type DateHeaderProps = {
  orderId: string
  date: string
  deliveryDays: Immutable.Map<string, any>
  slotId: string
  clearSlot: () => void
}

export const DateHeader: React.FC<DateHeaderProps> = (props) => {
  const { orderId, date, deliveryDays, slotId, clearSlot } = props

  if (orderId) {
    return (
      <div className={css.row}>
        <p className={css.deliverySlotText}>
          Edit recipes for your upcoming order. To change date or cancel order, visit{' '}
          <Link href={clientRoutes.myDeliveries} size={1} style={{ boxShadow: 'none' }}>
            &apos;Upcoming deliveries&apos;
          </Link>
        </p>
        <p className={css.dateText}>{`${moment(date).format('ddd Do MMM')}, ${getSlotTimes({
          date,
          deliveryDays,
          slotId,
        })}`}</p>
      </div>
    )
  }
  const text = `${moment(date).format('ddd Do MMM')}, ${getSlotTimes({
    date,
    deliveryDays,
    slotId,
  })}`

  return (
    <div className={css.rowSMMargin} data-testid="edit-delivery-slot">
      <Button fill={false} width="full">
        <Segment onClick={clearSlot} fill={false}>
          <span className={text.length > 21 ? css.limitedLengthPadding : css.limitedLength}>
            {text}
          </span>
          <span className={css.clear}>
            <span className={css.clearIcon} />
            edit
          </span>
        </Segment>
      </Button>
    </div>
  )
}
