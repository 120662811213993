import React from 'react'

import { Button, ButtonColorVariant } from '@gousto-internal/citrus-react'
import { Grid, Column } from 'goustouicomponents'

import { Resubscribe } from '../Resubscribe'
import { Section } from '../components/Section'
import { isModernResubscribeEnabled } from '../isModernResubscribeEnabled'
import { orderABoxSection, resubscribeSection } from '../subscriptionsSectionsContent'
import { trackSubscriptionSettingsChange } from '../tracking'
import { LegacyResubscribe } from './sections/LegacyResubscribe'
import { OrderABox } from './sections/OrderABox'

type Props = {
  accessToken: string
  showResubscribe: boolean
  setShowResubscribe: (showResubscribe: boolean) => void
}

export const PausedSubscription = ({ accessToken, showResubscribe, setShowResubscribe }: Props) => {
  const disableLegacyResubscribeView = isModernResubscribeEnabled()
  const handleBackClick = () => setShowResubscribe(false)
  const handleSubmitCompleted = () => setShowResubscribe(false)
  const handleResubscribeCtaClick = () => {
    setShowResubscribe(true)
    trackSubscriptionSettingsChange({ settingName: 'reactivate_subscription_cta', action: 'click' })
  }

  if (showResubscribe) {
    return (
      <Resubscribe
        data-testid="resubscribe-screen"
        accessToken={accessToken}
        onBackClick={handleBackClick}
        onSubmitCompleted={handleSubmitCompleted}
      />
    )
  }

  return (
    <Grid>
      {!disableLegacyResubscribeView && (
        <Column smallScreen={12} mediumScreen={6} largeScreen={6} hasPaddingSmallScreen={false}>
          <LegacyResubscribe accessToken={accessToken} />
        </Column>
      )}

      {disableLegacyResubscribeView && (
        <Column smallScreen={12} mediumScreen={6} largeScreen={6} hasPaddingSmallScreen={false}>
          <Section
            title={resubscribeSection.title}
            subTitle={resubscribeSection.subTitle}
            testingSelector={resubscribeSection.testingSelector}
          >
            <Button
              data-testid="resubscribe-cta"
              onClick={handleResubscribeCtaClick}
              colorVariant={ButtonColorVariant.Primary}
              width="100%"
              id="resubscribe-cta"
            >
              Reactivate subscription
            </Button>
          </Section>
        </Column>
      )}
      <Column smallScreen={12} mediumScreen={6} largeScreen={6} hasPaddingSmallScreen={false}>
        <Section
          title={orderABoxSection.title}
          subTitle={orderABoxSection.subTitle}
          testingSelector={orderABoxSection.testingSelector}
        >
          <OrderABox />
        </Section>
      </Column>
    </Grid>
  )
}
