import React, { useContext, useState } from 'react'

import {
  AlignItems,
  Body1,
  Body2,
  Box,
  Color,
  Display,
  FontFamily,
  Icon,
  JustifyContent,
  Link,
  useModal,
} from '@gousto-internal/citrus-react'

import { getSelectedBoxSize } from 'routes/Account/Subscription/context/selectors/box'

import { portionSizesConfig } from '../../../../../../config/portionSizes'
import {
  ModalBoxSize,
  SUBSCRIPTION_CHANGE_BOXES_MODAL_NAME,
} from '../../../ActiveSubscription/sections/YourSubscriptionDetails/ModalBoxSize/ModalBoxSize'
import { SubscriptionContext } from '../../../context'
import { actionTypes } from '../../../context/reducers'
import { getSubscriptionPortionOptions } from '../../../context/selectors/subscriptionOptions'
import { trackSubscriptionSettingsChange } from '../../../tracking'

export const BoxSizeSection = () => {
  const { state, dispatch } = useContext<any>(SubscriptionContext)
  const { openModal, closeCurrentModal } = useModal()
  const boxSizesOptions = getSubscriptionPortionOptions(state)
  const chosenBoxSize = getSelectedBoxSize(state)
  const [boxSize, setBoxSize] = useState<number>(chosenBoxSize)

  const onBoxSizeClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    openModal(SUBSCRIPTION_CHANGE_BOXES_MODAL_NAME)
    trackSubscriptionSettingsChange({ settingName: 'reactivate_box_size', action: 'edit' })({
      previous_box_size: boxSize,
    })
  }

  const handleBoxSizeSubmit = (selectedBoxSize: number) => {
    setBoxSize(selectedBoxSize)

    dispatch({
      type: actionTypes.UPDATE_SELECTED_BOX_SIZE,
      data: { numPortions: selectedBoxSize },
    })

    closeCurrentModal()
  }

  return (
    <>
      <ModalBoxSize
        isModalOpen={false}
        currentBoxSize={Number(boxSize)}
        boxSizesOptions={boxSizesOptions}
        portionSizesConfig={portionSizesConfig}
        onBoxSizeChange={handleBoxSizeSubmit}
        onModalClose={closeCurrentModal}
      />

      <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
        <Box width={['2.5rem']}>
          <Icon name="serving_size" />
        </Box>
        <Box width={['100%']}>
          <Body1 fontFamily={FontFamily.Bold} color={Color.ColdGrey_800}>
            Box size
          </Body1>
          <Body2>{`For ${boxSize} people`}</Body2>
        </Box>
        <Box
          width={['3rem']}
          display={Display.Flex}
          alignItems={AlignItems.Baseline}
          justifyContent={JustifyContent.FlexEnd}
        >
          <Link href="#box-size" onClick={onBoxSizeClick} data-testid="boxSizeEditButton">
            Edit
          </Link>
        </Box>
      </Box>
    </>
  )
}
