export const viewRecipeList = 'view_recipe_list'
export const recipesShown = 'recipes_shown'
export const soldOutRecipes = 'sold_out_recipes'
export const removeRecipe = 'remove_recipe'
export const addRecipe = 'add_recipe'
export const placeOrder = 'place_order'
export const placeOrderAttemptComplete = 'place_order_attempt_complete'
export const placeOrderFailAttempt = 'place_order_fail_attempt'
export const placeOrderAttempt = 'place_order_attempt'
export const receiveMenuServiceData = 'receive_menu_service_data'
export const changeMenuRecipeDetailVisibility = 'change_menu_recipe_detail_visibility'
export const completeMenuLoad = 'complete_menu_load'
export const changeLoginVisibility = 'change_login_visibility'
export const changeBoxSummaryVisibility = 'change_box_summary_visibility'
export const changeBasketSlot = 'change_basket_slot'
export const changeBasketPostcode = 'change_basket_postcode'
export const basketLimit = 'basket_limit'
export const checkOutBasketAttempt = 'check_out_basket_attempt'
export const selectPortionSize = 'select_portion_size'
export const viewTutorialModal = 'view_tutorial_modal'
export const dismissTutorialModal = 'dismiss_tutorial_modal'
export const loadBasketOrder = 'load_basket_order'
export const checkOutBasketComplete = 'check_out_basket_complete'
export const clickRecipeNavigation = 'click_recipe_navigation'
export const clickRecipeNavigationFooter = 'click_recipe_navigation_footer'
export const clickAllRecipes = 'click_all_recipes'
export const clickRateRecipes = 'click_rate_recipes'
export const clickGetStarted = 'click_get_started'
export const closePropositionTile = 'close_proposition_tile'
export const selectBoxSize = 'select_box_size'
export const showPropositionTile = 'show_proposition_tile'
export const selectPostcode = 'select_postcode'
export const selectDeliverySlot = 'select_delivery_slot'
export const clickCheckout = 'click_checkout'
export const basketEligible = 'basket_eligible'
export const boxSummary = 'box_summary'
export const menu = 'menu'
export const unavailableRecipeList = 'unavailable_recipe_list'
export const undoDeliveryOptionsChange = 'undo_delivery_options_change'
export const checkoutClickContinueToDelivery = 'checkout_click_continue_to_delivery'
export const checkoutClickContinueToPayment = 'checkout_click_continue_to_payment'
export const clickCheckoutSecurely = 'checkout_click_checkout_securely'
export const clickSubmitOrder = 'checkout_click_start_your_subscription'
export const subscriptionCreated = 'subscription_created'
export const selectCardPayment = 'checkout_select_card_payment'
export const selectPayPalPayment = 'checkout_select_paypal'
export const clickConfirmPayPal = 'confirm_paypal'
export const clickContinuePayPal = 'click_continue_paypal'
export const clickCancelPayPal = 'click_cancel_paypal'
export const signupChallengeModalDisplay = 'checkout_3dsmodal_display'
export const signupChallengeSuccessful = 'checkout_3ds_successful'
export const signupChallengeFailed = 'checkout_3ds_failed'
export const createUser = 'create_user'
export const createOrder = 'create_order'
export const clickClaimDiscountBar = 'click_claim_discount_bar'
export const clickClaimDiscountPopup = 'click_claim_discount_popup'
export const clickCloseDiscountPopup = 'click_close_discount_popup'
export const clickCloseDiscountFailurePopup = 'click_close_discount_failure_banner'
export const clickGodClaimDiscountPopup = 'click_god_claim_discount_popup'
export const clickCloseGodDiscountPopup = 'click_close_god_discount_popup'
export const clickCloseGodFailureHomepage = 'click_close_god_failure_homepage'
export const discountPopupDisplayed = 'discount_popup_displayed'
export const recipeVariantActionSheet = 'recipe_variant_action_sheet'
export const selectRecipeVariant = 'select_recipe_variant'
export const clickMerchandisingBanner = 'click_merchandising_banner'
export const menuTimeToUsable = 'menu_time_to_usable'
export const clickHelpFooter = 'click_help_footer'
export const optimizelyRolloutsExperiment = 'optimizely_rollouts_experiment'
export const clickAppBannerInstall = 'click_app_banner_install'
export const viewAppBanner = 'view_top_app_banner'
export const viewAppModal = 'view_app_promotion_modal'
export const clickAppModalInstall = 'click_app_modal_install'
export const clickAppStoreLoginButton = 'click_app_store_login_button'
export const clickPlayStoreLoginButton = 'click_play_store_login_button'
export const collectionNavShown = 'collection_nav_shown'
export const clickBuildMyBox = 'click_build_my_box'
export const clickBuildMyBox60 = 'build_my_box_60'
export const recoverSubscription = 'recover_subscription'
export const continueToPause = 'continue_to_pause'
export const osrOrdersSkipped = 'osr_orders_skipped'
export const viewMultiSkipBoxesScreen = 'view_multiskip_boxes_screen'
export const discountVisibilityBannerDisplayed = 'discount_visibility_banner_displayed'
export const clickAccountBreadcrumb = 'checkout_click_breadcrumb_account'
export const clickDeliveryBreadcrumb = 'checkout_click_breadcrumb_delivery'
export const clickPaymentBreadcrumb = 'checkout_click_breadcrumb_payment'
export const checkoutClickHideOrderSummary = 'checkout_click_hide_order_summary'
export const checkoutClickShowOrderSummary = 'checkout_click_show_order_summary'
export const clickSeeThisWeeksMenu = 'click_see_this_weeks_menu'
export const checkoutError = 'checkout_validation_error'
export const clickHelpNavigation = 'click_help_navigation'
export const completeWizardBoxSize = 'complete_wizard_box_size'
export const completeWizardPostcode = 'complete_wizard_postcode'
export const completeWizardDeliveryDay = 'complete_wizard_delivery_day'
export const unexpectedSignup = 'unexpected_signup'
export const click3dsReenterCardDetails = '3ds_modal_click_reenter_card'
export const click3dsUpdateInfo = '3ds_modal_click_update_info'
export const click3dsModalClose = '3ds_modal_click_close'
export const checkoutClickPrivacyPolicy = 'click_marketing_consent_privacy_policy'
export const signupSocialBelongingBanner = 'community_customers_banner_displayed'
export const checkoutUrgencyInitialModalDisplayed = 'checkout_urgency_modal_displayed'
export const checkoutUrgencyInitialModalDismissed = 'checkout_urgency_modal_dismissed'
export const checkoutUrgencyInitialModalPrimaryButtonClicked =
  'click_continue_to_checkout_urgency_modal'
export const checkoutUrgencyExpiredModalDisplayed = 'checkout_session_expired_modal_displayed'
export const checkoutUrgencyExpiredModalDismissed = 'checkout_session_expired_modal_dismissed'
export const checkoutUrgencyExpiredModalPrimaryButtonClicked =
  'click_back_to_menu_checkout_urgency_modal'
export const signupCheckAccountNewCustomer = 'click_god_new_customer'
export const signupCheckAccountExistingCustomer = 'click_god_existing_customer'
export const signupApplyVoucher = 'click_apply_voucher_existing_account'
export const signupClickVoucherInfo = 'click_god_voucher_info'
export const recordPerformanceMetric = 'record_performance_metric'
export const clickViewBasket = 'view_basket'
export const boxPricesClickTab = 'box_prices_click_tab'
export const boxPricesClickRecipeNumber = 'box_prices_click_recipe_number'
export const dismissRecipesInBasketProgress = 'click_close_action_bar'
export const clickEnterPromoCodeManuallyContinue = 'click_discount_modal_continue'
export const clickEnterPromoCodeManuallyButton = 'click_other_discount_code_popup'
export const cuisineSelected = 'cuisine_selected'
export const cuisineDeselected = 'cuisine_deselected'
export const signupPersonalisationComplete = 'signup_personalisation_complete'
export const marketCategory = 'market_category'
export const marketProductAdded = 'market_product_added'
export const marketProductRemoved = 'market_product_removed'
export const clickMoreRecipeDetails = 'click_more_recipe_details'
export const recipeCollectionSelected = 'RECIPE_COLLECTION_SELECTED'
export const menuRecipeDetailsShown = 'menu_recipe_details_shown'
export const carbonCuttingCuisineSelected = 'carbon_cutting_cuisine_selected'
export const identifyApplePayEligibility = 'identify_apple_pay_eligibility'
export const clickApplePayPay = 'click_applepay_pay'
export const applePayStatusFetchError = 'apple_pay_status_fetch_error'
export const clickedPriceBreakdown = 'clicked_price_breakdown'
export const closeDiscountAppliedBar = 'close_discount_banner'
export const safePlaceDropDownSelected = 'safe_place_drop_down_selected'
export const deliveryInstructionDetailsClick = 'delivery_instruction_details_click'
export const showBrowseCta = 'show_browse_cta'
export const promoCodeInvalid = 'promo_code_invalid'
export const editBoxSizeOrDate = 'edit_box_size_or_date'
export const deliveryOptionsPostCodeShown = 'delivery_options_postcode_shown'
export const recipeChangeWarning = 'recipe_change_warning'
export const radioBoxSizeSelected = 'box_size_radio_selected'
export const everydayFavouritesHelp = 'everyday_favourites_help'
export const healthHubHelp = 'health_hub_help'
export const speedyMealsHelp = 'speedy_10_min_meals_help'
export const discountBannerSixtyTwenty = 'discount_banner_60_20'
export const menuSearchButtonClick = 'search_modal_popup',
