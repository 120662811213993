import { connect } from 'react-redux'

import {
  fetchIngredientIssues,
  storeSelectedIngredientIssue,
  trackIngredientIssues
} from 'actions/getHelp'
import { IngredientIssues } from './IngredientIssues.logic'
import { getSelectedIngredients } from '../selectors/selectors'

const mapStateToProps = (state) => {
  const { getHelp } = state
  const ingredients = getSelectedIngredients(state)
  const issues = getHelp.get('ingredientIssues').toJS()
  const subIssues = getHelp.get('ingredientSubIssues').toJS()

  return {
    content: {
      title: 'Get help with your box',
      body: 'Please let us know what was wrong with the ingredient(s)',
      button1Copy: 'Continue',
    },
    ingredients,
    issues,
    subIssues,
  }
}

const IngredientIssuesContainer = connect(mapStateToProps, {
  fetchIngredientIssues,
  storeSelectedIngredientIssue,
  trackIngredientIssues,
})(IngredientIssues)

export {
  IngredientIssuesContainer
}
