import { useCallback } from 'react'

import { DeliveryDay } from './useSubscriptionToDeliveryDays'

export type CalendarDeliverDay = {
  id: string
  slotId: string
  date: string
  defaultDay: number
  activeForSubscribersOneOff: boolean
}

export const useSubscriptionCalendar = (days: DeliveryDay[] | null) => {
  const getFilteredDays = useCallback(
    () =>
      days?.map((day) => ({
        id: day.id,
        date: day.date,
        defaultDay: day.daySlotLeadTimes[0].defaultDay,
        slotId: day.daySlotLeadTimes[0].slotId,
        activeForSubscribersOneOff: day.daySlotLeadTimes[0].activeForSubscribersOneOff,
      })),
    [days],
  )

  if (!days) {
    return {
      getCalendarWeekDays: () => [],
      getCalendarDays: () => [],
      getInitialDate: () => '',
      getLastDate: () => '',
    }
  }

  const filteredDays = getFilteredDays() as CalendarDeliverDay[]
  const filteredDaysWithoutInactiveSlots = (getFilteredDays() as CalendarDeliverDay[]).filter(
    (day) => day.activeForSubscribersOneOff,
  )

  const getCalendarWeekDays = () => ['M', 'T', 'W', 'T', 'F', 'S', 'S']
  const getWeekDayForInitialSlot = () => {
    const weekDay = new Date(days[0].date).getDay()

    return weekDay === 0 ? 6 : weekDay - 1
  }

  const getCalendarDays = () => {
    const addEmptyDays = new Array(getWeekDayForInitialSlot()).fill(null)
    const addDaysFromSlots = addEmptyDays.concat(filteredDays)

    const groupDaysByWeek = addDaysFromSlots.reduce(
      (prev: CalendarDeliverDay[][], current: CalendarDeliverDay, index: number) => {
        if (index % getCalendarWeekDays().length === 0) {
          prev.push([])
        }

        prev[prev.length - 1].push(current)

        return prev
      },
      [],
    ) as CalendarDeliverDay[][]

    return groupDaysByWeek
  }

  return {
    getCalendarWeekDays,
    getCalendarDays,
    getInitialDate: () => filteredDaysWithoutInactiveSlots[0].date,
    getLastDate: () =>
      filteredDaysWithoutInactiveSlots[filteredDaysWithoutInactiveSlots.length - 1].date,
  }
}
