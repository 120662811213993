import { useDecision } from '@optimizely/react-sdk'

import { useAuth } from 'routes/Menu/domains/auth'

export function useIsBoxPricesDiscountVisibilityEnabled() {
  const [isBoxPricesDiscountVisibilityEnabled] = useDecision(
    'beetroots_ofx_box_prices_discount_visibility',
  )
  const { isAuthenticated } = useAuth()
  if (isAuthenticated) return false

  return isBoxPricesDiscountVisibilityEnabled.variationKey === 't1'
}
