import React from 'react'
import { TrustPilotSection } from './components/TrustPilotSection/TrustPilotSection'
import type { Recipe } from './types/endlessVarietyCarousel'
import { EffortlessHomeCookingSection } from './components/EffortlessHomeCookingSection/EffortlessHomeCookingSection'
import { Box } from '@gousto-internal/citrus-react'
import { ValueYouCanTasteSection } from './components/ValueYouCanTasteSection/ValueYouCanTasteSection'
import { EndlessVarietySection } from './components/EndlessVarietySection'
import { BrandEndorsementsSection } from './components/BrandEndorsementsSection/BrandEndorsementsSection'
import { EnvironmentalBenefitsSection } from './components/EnvironmentalBenefitsSection/EnvironmentalBenefitsSection'

export type LandingPageContentProps = {
  onSignupCTAClick: () => void
  recipes: Record<string, Recipe>
  signupCTA: string
}

export function LandingPageContent({
  onSignupCTAClick,
  recipes,
  signupCTA,
}: LandingPageContentProps) {
  return (
    <Box data-testid="landing-page-content">
      <TrustPilotSection />
      <EffortlessHomeCookingSection signupCTA={signupCTA} onCTAClick={onSignupCTAClick} />
      <ValueYouCanTasteSection signupCTA={signupCTA} onCTAClick={onSignupCTAClick} />
      <EndlessVarietySection
        signupCTA={signupCTA}
        onCTAClick={onSignupCTAClick}
        recipes={recipes}
      />
      <BrandEndorsementsSection />
      <EnvironmentalBenefitsSection signupCTA={signupCTA} onCTAClick={onSignupCTAClick} />
    </Box>
  )
}
