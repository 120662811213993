import { useContext } from 'react'

import { OptimizelyContext } from '@optimizely/react-sdk'

import { useAuth } from 'routes/Menu/domains/auth'
import Cookies from 'utils/GoustoCookies'
import logger from 'utils/logger'

type TwoDaysDelivery10PMCutOffKey = 'c' | 't1' | 't2'
type TwoDaysDelivery10PMCutOffValue = 'cohort_a' | 'cohort_b' | 'cohort_c' | undefined

export function useGet2DD10PMCutOff(): [boolean, TwoDaysDelivery10PMCutOffValue] {
  const mapVariationKeyToValue: Record<
    TwoDaysDelivery10PMCutOffKey,
    TwoDaysDelivery10PMCutOffValue
  > = {
    c: 'cohort_a',
    t1: 'cohort_b',
    t2: 'cohort_c',
  }

  const { isAuthenticated } = useAuth()
  const { optimizely } = useContext(OptimizelyContext)
  let hasAcceptedAnalytics = false
  try {
    const consentCookieString = Cookies.get('v1_gousto_cookie_consent')
    const consentCookie = consentCookieString ? JSON.parse(consentCookieString) : {}
    hasAcceptedAnalytics = consentCookie?.categories?.includes('ANALYTICS')
  } catch (error) {
    if (error instanceof Error) {
      // eslint-disable-next-line prettier/prettier
      ;(logger as any).error(error.message)
    }
  }

  // [TG-8940] We only allocate users to the experiment if they have consented
  // to the Analytics cookies and are not authenticated
  const { enabled, variationKey } =
    optimizely && hasAcceptedAnalytics && !isAuthenticated
      ? optimizely.decide('beetroots_ofx_2dd_10pm_cutoff_bis')
      : { enabled: false, variationKey: undefined }

  const isEnabled = enabled && !!variationKey && ['t1', 't2'].includes(variationKey)
  const value =
    isEnabled && variationKey
      ? mapVariationKeyToValue[variationKey as TwoDaysDelivery10PMCutOffKey]
      : undefined

  return [isEnabled, value]
}
