import React from 'react'

import { RibbonTriggerContainer } from 'RibbonTrigger'

import { useIsOptimizelyFeatureEnabled } from 'containers/OptimizelyRollouts'
import { AboutYou } from 'routes/Checkout/Components/AboutYou/AboutYou'
import { CheckoutStepIds } from 'routes/Checkout/checkoutConfig'

import { CheckoutTrustPilot } from './CheckoutTrustPilot'

type CreateAccountProps = {
  onStepChange: (stepId: CheckoutStepIds) => void
}

export const CreateAccount = ({ onStepChange }: CreateAccountProps) => {
  const isCheckoutSocialProofEnabled = useIsOptimizelyFeatureEnabled(
    'beetroots_checkout_social_proof_enabled',
  )

  return (
    <>
      <AboutYou onStepChange={onStepChange} />
      <RibbonTriggerContainer name="checkout-account" />
      {isCheckoutSocialProofEnabled && <CheckoutTrustPilot />}
    </>
  )
}
