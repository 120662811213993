import { actionTypes } from './actionTypes'

const tempActions = {
  temp: (key, value) => ({
    type: actionTypes.TEMP,
    key,
    value,
  }),
  tempReset: () => ({ type: actionTypes.TEMP_RESET }),
}

export default tempActions
