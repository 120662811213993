import React, { useState } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import styled from '@emotion/styled'
import {
  Body2,
  Box,
  CSSKeywords,
  Color,
  Display,
  FontFamily,
  Heading2,
  Icon,
  IconVariant,
  ScreenReaderLiveRegion,
  Space,
  Text,
  Theme,
  colors,
  useTheme,
} from '@gousto-internal/citrus-react'
import { useDispatch, useSelector } from 'react-redux'

import { validateEmail } from '@library/api-user'

import { actionTypes } from 'actions/actionTypes'
import { trackUTMAndPromoCode } from 'actions/tracking'
import { checkoutClickContinueToDelivery } from 'actions/trackingKeys'
import { userProspect } from 'actions/user'
import { useIsCheckoutFlexibilityAwarenessEnabled } from 'hooks/useIsCheckoutFlexibilityAwarenessEnabled'
import { useIsWizard2DDBannerEnabled } from 'hooks/useIsWizard2DDBannerEnabled'
import { EmailPasswordForm } from 'routes/Checkout/Components/AboutYou/EmailPasswordForm'
import { ErrorMessage } from 'routes/Checkout/Components/ErrorMessage/ErrorMessage'
import { CheckoutStepIds } from 'routes/Checkout/checkoutConfig'
import { getAmountOfCustomers, getDistrict } from 'routes/Signup/signupSelectors'

import { BoxFlexibilityAwareness } from '../BoxFlexibilityAwareness/BoxFlexibilityAwareness'
import { SectionHeader } from '../SectionHeader'
import { SecurePaymentBanner } from './SecurePaymentBanner'
import { ExistingEmailLoginModal } from './components/ExistingEmailLoginModal'
import { ExistingEmailWarningNotification } from './components/ExistingEmailWarningNotification'
import { ValidateEmailErrorDisplay } from './components/ValidateEmailErrorDisplay'

type AboutYouProps = {
  onStepChange: (nextStepId: CheckoutStepIds) => void
}

type ThemeProp = {
  citrusTheme: Theme
}

type ValidateEmailStatusType = null | 'error' | 'warning'

const AboutYouContainer = styled.div(({ citrusTheme }: ThemeProp) => {
  const mackerelColor = '#D2D6D9'
  const BreakpointFromMedium = `@media (min-width: ${citrusTheme.tokens.breakpoints[0]})`

  return {
    borderBottom: `1px solid ${mackerelColor}`,
    borderTop: `1px solid ${mackerelColor}`,
    padding: '1rem 0.75rem 1.5rem',
    paddingBottom: '1rem',
    background: colors[Color.White],

    [BreakpointFromMedium]: {
      border: `1px solid ${mackerelColor}`,
      padding: '1.5rem 1.5rem 2rem',
    },
  }
})

export const CommunityBanner = ({
  amountOfCustomers,
  district,
}: {
  amountOfCustomers: string
  district: string
}) => (
  <Box display={Display.Flex} data-testid="community-banner">
    <Icon name="serving_size" variant={IconVariant.Informative} />
    <Space size={2} direction="horizontal" />
    <Body2>
      Join our community of{' '}
      <Text fontFamily={FontFamily.Bold} display={Display.Inline} size={CSSKeywords.Inherit}>
        {amountOfCustomers} Gousto customers
      </Text>{' '}
      in {district} today!
    </Body2>
  </Box>
)

export const AboutYou = ({ onStepChange }: AboutYouProps) => {
  const dispatch = useDispatch()
  const { theme } = useTheme()
  const [validateEmailStatus, setValidateEmailStatus] = useState<ValidateEmailStatusType>(null)
  const h4FontSize = theme.tokens.typographyDefaults.h4.size
  const district = useSelector(getDistrict)
  const amountOfCustomers = useSelector(getAmountOfCustomers)
  const showSocialBelongingBanner = district && amountOfCustomers > 50
  const isWizard2DDEnabled = useIsWizard2DDBannerEnabled()
  const isCheckoutFlexibilityAwarenessEnabled = useIsCheckoutFlexibilityAwarenessEnabled()
  const showCommunityBanner = isWizard2DDEnabled && showSocialBelongingBanner

  const onSubmit = async (
    email: string,
    password: string,
    isEmailMarketingEnabled: boolean,
    isCompetitionsAndSurveysEnabled: boolean,
  ) => {
    setValidateEmailStatus(null)
    try {
      const { exists } = await validateEmail({ email: email.trim() })

      if (exists) {
        setValidateEmailStatus('warning')
        datadogRum.addAction('show_validate_email_warning')

        return
      }
    } catch (err) {
      setValidateEmailStatus('error')

      return
    }

    dispatch({
      type: actionTypes.CHECKOUT_SET_EMAIL,
      email,
    })
    dispatch({ type: actionTypes.CHECKOUT_SET_IS_EMAIL_MARKETING_ENABLED, isEmailMarketingEnabled })
    dispatch({
      type: actionTypes.CHECKOUT_SET_IS_COMPETITIONS_AND_SURVEYS_ENABLED,
      isCompetitionsAndSurveysEnabled,
    })
    dispatch(trackUTMAndPromoCode(checkoutClickContinueToDelivery))
    dispatch(userProspect())
    onStepChange(CheckoutStepIds.DELIVERY)
  }

  return (
    <>
      <ExistingEmailLoginModal />
      <AboutYouContainer citrusTheme={theme} data-testing="checkoutAboutYouSection">
        {!showCommunityBanner && <SectionHeader title="Create account" />}
        {showCommunityBanner && (
          <>
            <Heading2 size={h4FontSize}>Create account</Heading2>
            <Space size={2} />
            <CommunityBanner
              amountOfCustomers={amountOfCustomers.toLocaleString()}
              district={district}
            />
            <Space size={4} />
          </>
        )}
        <ScreenReaderLiveRegion urgency="polite">
          {validateEmailStatus === 'warning' && <ExistingEmailWarningNotification />}
        </ScreenReaderLiveRegion>
        <ScreenReaderLiveRegion urgency="polite">
          {validateEmailStatus === 'error' && <ValidateEmailErrorDisplay />}
        </ScreenReaderLiveRegion>
        <EmailPasswordForm onSubmit={onSubmit} />
        <ErrorMessage />
      </AboutYouContainer>
      {isCheckoutFlexibilityAwarenessEnabled && (
        <Box paddingH={[3, 0]}>
          <Space size={4} />
          <BoxFlexibilityAwareness />
        </Box>
      )}
      <SecurePaymentBanner />
    </>
  )
}
