import { composeFetch } from '@library/http'
import {
  composeRequest,
  addPath,
  setServiceUrl,
  setMethod,
  setQueryParams,
  setHeaders,
} from '@library/http/requests'
import { MenuSearch } from './types'

type MenuSearchRequestParams = {
  accessToken?: string
  deliveryDate: string
  menuId: string
  numPortions: number
  search: string
  userId?: string
}

const menuApiMenuSearchParse = async (response: Response): Promise<MenuSearch> => {
  const body = await response.text()
  const parsedBody = JSON.parse(body)

  if (!response.ok) {
    throw new Error(parsedBody.message)
  }

  return parsedBody as MenuSearch
}

const menuApiMenuSearchRequest = composeRequest(
  setServiceUrl('menu', 3),
  addPath(({ menuId }: MenuSearchRequestParams) => `menus/${menuId}/search`),
  setQueryParams(({ deliveryDate, numPortions, search, userId }: MenuSearchRequestParams) => ({
    delivery_date: deliveryDate,
    num_portions: numPortions.toString(),
    search: search,
    ...(userId && { user_id: userId }),
  })),
  setHeaders(({ accessToken }) => {
    return {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    }
  }),
  setMethod('GET'),
)

export const menuSearchFetcher = composeFetch(menuApiMenuSearchRequest, menuApiMenuSearchParse)
