import React, { useEffect, useRef, useState } from 'react'

import { usePromocodeData } from '../homeHooks'
import { DiscountBar } from './DiscountBar/DiscountBar'
import { useCanShowPromoBanner, useGetPromoBannerDispatchedActions } from './promoBannerHooks'

interface PromoBannerProps {
  trackingEventName?: string
}

export const PromoBanner: React.FC<PromoBannerProps> = ({ trackingEventName }) => {
  const stickyBarRef = useRef<HTMLDivElement>(null)
  const [isSticky, setIsSticky] = useState(false)
  const { dispatchApplyPromoCodeAndShowModal, dispatchRedirect, dispatchTrackUTMAndPromoCode } =
    useGetPromoBannerDispatchedActions()
  const canShowBanner = useCanShowPromoBanner()
  const { defaultBannerText } = usePromocodeData()

  const onScroll = () => {
    const bannerContainer = stickyBarRef.current
    setIsSticky(bannerContainer ? window.pageYOffset > bannerContainer.offsetTop : false)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  const applyDiscount = () => {
    dispatchApplyPromoCodeAndShowModal()
    dispatchRedirect()
    dispatchTrackUTMAndPromoCode(trackingEventName)
  }

  return (
    <div ref={stickyBarRef}>
      <DiscountBar
        applyDiscount={applyDiscount}
        isHidden={!canShowBanner}
        isSticky={isSticky}
        promoText={defaultBannerText}
      />
    </div>
  )
}
