import { queueTrackingEvent } from '@library/tracking-integration'

export enum TrackingActions {
  CLICK_GET_STARTED = 'click_get_started',
  CLICK_OPEN_MENU = 'click_open_menu',
}

export enum HomepageSections {
  HERO = 'hero',
  FLOATING_CTA = 'floatingCTA',
  HERO_CAROUSEL = 'heroCarousel',
}

type CTAClickTrackingData = {
  action: TrackingActions
  properties: {
    section: HomepageSections
  }
}

type CarouselSlideChangeTrackingData = {
  action: 'carousel_slide_change'
  properties: {
    prevSlideIndex: number
    currentSlideIndex: number
    [key: string]: unknown
  }
}

export function trackCTAClick(action: TrackingActions, homepageSection: HomepageSections) {
  queueTrackingEvent<CTAClickTrackingData>({
    action,
    properties: {
      section: homepageSection,
    },
  })
}

export function trackCarouselSlideChange(
  prevSlideIndex: number,
  currentSlideIndex: number,
  snowplowTrackingProperties: Record<string, unknown> = {},
) {
  queueTrackingEvent<CarouselSlideChangeTrackingData>({
    action: 'carousel_slide_change',
    properties: {
      prevSlideIndex,
      currentSlideIndex,
      ...snowplowTrackingProperties,
    },
  })
}
