import React from 'react'

import { Checkbox, Text } from '@gousto-internal/citrus-react'

import { FieldContainer } from 'routes/Checkout/Components/AboutYou/FieldContainer'

type CompetitionsAndSurveysCheckboxFieldProps = {
  id: string
  checked: boolean
  onChange: () => void
}

export const CompetitionsAndSurveysCheckboxField = ({
  id,
  checked,
  onChange,
}: CompetitionsAndSurveysCheckboxFieldProps) => (
  <FieldContainer>
    <Checkbox
      id={id}
      data-testid="checkoutAllowCompetitionsAndSurveysCheckbox"
      checked={checked}
      onChange={onChange}
    >
      <Text size={1}>
        I’d like Gousto to invite me to take part in competitions and surveys, and share offers from
        other Gousto-approved brands.
      </Text>
    </Checkbox>
  </FieldContainer>
)
