import {
  Box,
  Display,
  Col,
  DisplayPosition,
  Grid,
  Space,
  FlexDirection,
  JustifyContent,
  Image,
  useTheme,
  withCitrusTheme,
  Color,
  ZIndex,
  Overflow,
} from '@gousto-internal/citrus-react'
import React from 'react'

import { CTABlock, DonationSection, EnvironmentalBenefitsCopy, HeaderSection } from './components'

import BottomChickPeas from '../../assets/EnvironmentalBenefitsSectionAssets/BottomChickPeas.png'
import TopChickPeas from '../../assets/EnvironmentalBenefitsSectionAssets/TopChickPeas.png'
import XWideChickPeas from '../../assets/EnvironmentalBenefitsSectionAssets/XWideChickPeas.png'
import ecoFriendlyBoxWebP from '../../assets/EnvironmentalBenefitsSectionAssets/EcoChillBox.webp'

import { ENVIRONMENTAL_BENEFITS_BULLET_ONE, ENVIRONMENTAL_BENEFITS_BULLET_TWO } from '../../config'

export type EnvironmentalBenefitsSectionProps = {
  signupCTA: string
  onCTAClick: () => void
}

export const EnvironmentalBenefitsSection = ({
  signupCTA,
  onCTAClick,
}: EnvironmentalBenefitsSectionProps) => {
  const { theme } = useTheme()
  const breakpointToLarge = `@media (max-width: ${theme.tokens.breakpoints[1]})`

  const GridWithBackgroundPattern = withCitrusTheme(Grid, () => ({
    [breakpointToLarge]: {
      backgroundImage: `url(${TopChickPeas})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top right',
    },
  }))

  const MainImage = withCitrusTheme(Box, () => ({
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(${ecoFriendlyBoxWebP})`,
  }))

  return (
    <section
      data-testid="environmentalBenefits"
      style={{ backgroundColor: `${theme.tokens.colors[Color.NeutralGrey_50]}` }}
    >
      <Box overflowX={Overflow.Hidden}>
        <GridWithBackgroundPattern>
          <Col
            size={[12, null, 6, null]}
            display={Display.Flex}
            paddingV={16}
            paddingH={[8, 20, 4, 8]}
            justifyContent={JustifyContent.FlexStart}
          >
            <Box zIndex={ZIndex.Masked} width={[null, null, '100%', '80%']}>
              <HeaderSection />
              <Space size={4} />
              <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
                <EnvironmentalBenefitsCopy
                  ENVIRONMENTAL_BENEFITS_BULLET={ENVIRONMENTAL_BENEFITS_BULLET_ONE}
                />
                <Space size={2} />
                <EnvironmentalBenefitsCopy
                  ENVIRONMENTAL_BENEFITS_BULLET={ENVIRONMENTAL_BENEFITS_BULLET_TWO}
                />
              </Box>
              <Space size={4} />
              <CTABlock signupCTA={signupCTA} onCTAClick={onCTAClick} />
              <Space size={4} />
              <DonationSection />
            </Box>
          </Col>
          <Col
            size={[12, null, 6, null]}
            position={DisplayPosition.Relative}
            height={['400px', null, 'auto', null]}
          >
            <Box
              position={DisplayPosition.Absolute}
              left={['10%', null, '-70%', null]}
              top={['-110px', null, 'initial']}
              height={'100%'}
              zIndex={ZIndex.Default}
            >
              <Box display={[Display.None, null, Display.Block, null]} height={'100%'}>
                <Image src={XWideChickPeas} srcSet={XWideChickPeas} alt="" />
              </Box>
              <Box display={[Display.Block, null, Display.None, null]}>
                <Image src={BottomChickPeas} srcSet={BottomChickPeas} alt="" />
              </Box>
            </Box>
            <MainImage
              position={DisplayPosition.Absolute}
              height="100%"
              width={['100%', null, '50vw', null]}
            />
          </Col>
        </GridWithBackgroundPattern>
      </Box>
    </section>
  )
}
