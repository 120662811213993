import { endpoint } from '@library/endpoint'

import { fetch } from 'utils/fetch'

export function fetchPromo(accessToken: string, promoCode: string): PromoResponse {
  const data = fetch(
    accessToken,
    `${endpoint('core')}/promocode/${promoCode}`,
    {},
    'GET',
    'default',
    {},
    null,
    false,
  )

  return data
}

interface PromoResponse {
  data: {
    code: string
    details: {
      'discount-whole-order-percent': string
    }
    codeData: {
      code: string
      campaign: {
        id: number
        media_id: any
        landing_details_1: string
        landing_details_2: string
        landing_details_3: string
        landing_url: string
        modal_text: string
        raw_modal_text: string
        modal_title: string
        enabled: boolean
        is_for_existing: boolean
        expiry_interval: string
        is_boost: boolean
        boost_campaign_id: any
      }
    }
    addGiftOrderRules: any[]
  }
  meta: any[]
}
