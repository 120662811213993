import { frequencyMapping } from '../enum/frequency'
import { dateFormatter } from './utils/dateFormatter'

const PREFIX = 'and delivered'

export function generateNextDeliveryFrequencyString(
  deliveryFrequency: keyof typeof frequencyMapping,
  deliveryDate: string | null,
) {
  if (!deliveryDate) {
    return `${PREFIX} ${frequencyMapping[deliveryFrequency]}`
  }

  const day = dateFormatter().getWeekDayName(deliveryDate)

  switch (deliveryFrequency) {
    case 1:
      return `${PREFIX} every ${day} thereafter`

    case 2:
      return `${PREFIX} every other ${day}`

    case 4:
      return `${PREFIX} once a month thereafter`

    default:
      return ''
  }
}
