import React, { useState, ChangeEvent } from 'react'

import {
  Box,
  Modal,
  ModalHeader,
  Heading3,
  ModalButton,
  ButtonColorVariant,
  ModalFooter,
  ModalClose,
} from '@gousto-internal/citrus-react'
import { PortionSizeSelector } from 'PortionSizeSelector/PortionSizeSelector'

import { PortionSizes } from 'config/portionSizes'

import { trackSubscriptionSettingsChange } from '../../../../tracking'

export const SUBSCRIPTION_CHANGE_BOXES_MODAL_NAME = 'SUBSCRIPTION-CHANGE-BOX-SIZES'

type ModalBoxSizeProps = {
  isModalOpen: boolean
  currentBoxSize: number
  boxSizesOptions: PortionSizes[]
  portionSizesConfig: any
  onBoxSizeChange: (boxSize: number) => void
  onModalClose: () => void
}

export const ModalBoxSize = (props: ModalBoxSizeProps) => {
  const {
    currentBoxSize,
    boxSizesOptions,
    portionSizesConfig,
    onBoxSizeChange,
    onModalClose,
    isModalOpen,
  } = props
  const [selectedBoxSize, setSelectedBoxSize] = useState<PortionSizes>(
    currentBoxSize as PortionSizes,
  )

  const onSubmit = () => {
    onBoxSizeChange(selectedBoxSize)
    trackSubscriptionSettingsChange({
      settingName: 'reactivate_box_size',
      action: 'update',
    })({
      previous_box_size: currentBoxSize,
      updated_box_size: selectedBoxSize,
    })
  }

  const handleChangeBoxSize = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedBoxSize(Number(event.target.value) as PortionSizes)
  }

  return (
    <Modal name={SUBSCRIPTION_CHANGE_BOXES_MODAL_NAME} isOpen={isModalOpen}>
      <ModalHeader>
        <Heading3 size={9}> Change box size </Heading3>
        <ModalClose onClose={onModalClose} />
      </ModalHeader>
      <Box paddingH={[4, 6]} paddingV={[4, 6]}>
        <PortionSizeSelector
          portionSize={selectedBoxSize || currentBoxSize}
          availablePortionSizes={boxSizesOptions}
          config={portionSizesConfig}
          onChange={handleChangeBoxSize}
        />
      </Box>

      <ModalFooter>
        <ModalButton colorVariant={ButtonColorVariant.Primary} onClick={onSubmit}>
          Save
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}
