import desktopImageJpg from '../../assets/heroDesktop1x.jpg'
import desktopImageWebp from '../../assets/heroDesktop1x.webp'
import desktopImageLargeWebp from '../../assets/heroDesktop2x.webp'

import mobileImagePng from '../../assets/heroMob1x.png'
import mobileImageWebp from '../../assets/heroMob1x.webp'
import mobileImageLargeWebp from '../../assets/heroMob2x.webp'

export const HERO_CONFIG = {
  benefit: [
    {
      iconName: 'play_pause',
      ariaLabel: 'No lock in icon',
      strongText: 'No lock in: ',
      text: 'pause or cancel for free anytime',
    },
    {
      iconName: 'delivery',
      ariaLabel: 'Contactless delivery icon',
      strongText: 'Delivered to your door',
      text: ', 7 days a week',
    },
  ],
  heading: 'Your friend must like you a lot!',
}

export const IMAGES = {
  desktop: {
    webp: {
      x1: desktopImageWebp,
      x2: desktopImageLargeWebp,
    },
    png: desktopImageJpg,
  },
  mobile: {
    webp: {
      x1: mobileImageWebp,
      x2: mobileImageLargeWebp,
    },
    png: mobileImagePng,
  },
}

export const FloatingCTA = {
  floatingCTABoldText: 'No lock in: ',
  floatingCTAText: 'pause or cancel for free anytime',
}
