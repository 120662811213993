import { connect } from 'react-redux'
import { storeIngredientIssueDescriptions } from 'actions/getHelp'
import { trackIngredientReasonsConfirmed } from '../actions/getHelp'
import { IngredientReasons } from './IngredientReasons.logic'
import { getSelectedIngredients } from '../selectors/selectors'

const mapStateToProps = (state) => ({
  content: {
    title: 'Get help with your box',
    body: 'Please tell us a bit more about your problem ingredients.',
    secondBody: 'For example, please tell us how the damage ocurred, or the specific issue with the quality.',
    button1Copy: 'Submit details',
  },
  ingredientsAndIssues: getSelectedIngredients(state),
})

const IngredientReasonsContainer = connect(
  mapStateToProps, {
    trackIngredientReasonsConfirmed,
    storeIngredientIssueDescriptions,
  })(IngredientReasons)

export {
  IngredientReasonsContainer
}
