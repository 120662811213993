export const hero = {
  header: 'Mouthwatering home-cooked meals',
  subheader: 'Over 250 recipes a month from just £2.99 a meal*',
  benefits: '85+ veggie (incl vegan) recipes a month',
}

export const PROMO_CODE_DATA = {
  defaultPromoCode: 'DTI-SB-602020',
  defaultBannerText: 'Get 60% off your first order and 20% off your first two months',
  defaultDescriptionLines: [
    `Get 60% off your first order!`,
    `Then 20% off all other boxes you order in your first two months.`,
  ],
}

export const GIFTING_EXPERIMENT_PROMO_CODE_DATA = {
  defaultPromoCode: 'DTI-HP-GFT60',
  defaultBannerText:
    'Get 60% off your first order plus 20% off for two months and FREE DESSERT for three months**',
  defaultDescriptionLines: [
    `Get 60% off your first order!`,
    `Then 20% off all other boxes you order in your first two months and FREE DESSERT for three months.`,
  ],
}

export const RAF_LANDING_PAGE_EXPERIMENT_PROMO_CODE_DATA = {
  defaultPromoCode: 'DTI-SB-602020',
  defaultBannerText: 'Get 65% off your first order and 25% off your first two months',
  defaultDescriptionLines: [
    `Get 65% off your first order!`,
    `Then 25% off all other boxes you order in your first two months.`,
  ],
}

// This is a Brand PPC version of the discount with a free gift
export const BPPC_GIFTING_EXPERIMENT_PROMO_CODE_DATA = {
  defaultPromoCode: 'GB-DINNER-SEA-BR-GFT-6020',
}

export const knownVariants = ['default', 'tv']

export const defaultVariant = 'default'

export const seo = {
  title: 'Recipe Boxes | Get Fresh Food & Recipes Delivered | Gousto',
  meta: [
    {
      name: 'description',
      content:
        'Change the way you eat with our easy to follow recipes. We deliver fresh boxes of ingredients and delicious recipes 7 days a week. Get started now!',
    },
    {
      name: 'keywords',
      content: 'Gousto, recipe delivery, ingredients, fresh, healthy food, cooking, recipe box',
    },
  ],
}

export const homeConfig = {
  hero,
  PROMO_CODE_DATA,
  knownVariants,
  defaultVariant,
  seo,
}
