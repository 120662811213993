import React, { ChangeEvent, useCallback, useState } from 'react'

import {
  ModalClose,
  Heading3,
  ButtonColorVariant,
  ButtonSize,
  ModalButton,
  ModalFooter,
  useModal,
  Modal,
  ModalHeader,
  ModalBody,
} from '@gousto-internal/citrus-react'

import { trackSubscriptionSettingsChange } from 'routes/Account/Subscription/tracking'

import { FrequencyOptions } from './FrequencyOptions'

export const SUBSCRIPTION_CHANGE_FREQUENCY_MODAL_NAME = 'SUBSCRIPTION-CHANGE-FREQUENCY'

export const ChangeFrequencyModal = ({
  isModalOpen,
  currentDeliveryFrequency,
  onFrequencySubmit,
}: {
  isModalOpen: boolean
  currentDeliveryFrequency: string
  onFrequencySubmit: (selectedFrequency: string) => void
}) => {
  const [selectedFrequency, setSelectedFrequency] = useState(currentDeliveryFrequency)
  const { closeCurrentModal } = useModal()

  const handleFrequencyChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFrequency(event.target.value)
  }, [])

  const handleSubmit = () => {
    onFrequencySubmit(selectedFrequency)

    trackSubscriptionSettingsChange({
      settingName: 'reactivate_box_frequency',
      action: 'update',
    })({
      previous_frequency: currentDeliveryFrequency,
      updated_frequency: selectedFrequency,
    })

    closeCurrentModal()
  }

  return (
    <Modal isOpen={isModalOpen} name={SUBSCRIPTION_CHANGE_FREQUENCY_MODAL_NAME}>
      <ModalClose onClose={closeCurrentModal} />
      <ModalHeader>
        <Heading3>Change Frequency</Heading3>
      </ModalHeader>
      <ModalBody>
        <FrequencyOptions selectedFrequency={selectedFrequency} onChange={handleFrequencyChange} />
      </ModalBody>
      <ModalFooter>
        <ModalButton
          colorVariant={ButtonColorVariant.Primary}
          size={ButtonSize.Large}
          onClick={handleSubmit}
        >
          Save
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}
