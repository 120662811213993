import { endpoint } from '@library/endpoint'

import config from 'config/products'
import routes from 'config/routes'
import { fetch } from 'utils/fetch'

const reqData = {
  image_sizes: config.fetchImageSizes,
  includes: config.fetchIncludes,
}

export function fetchProduct(accessToken, productId, userId) {
  const data = {
    ...reqData,
    userId,
  }

  return fetch(accessToken, `${endpoint('products', 2)}/products/${productId}`, data, 'GET')
}

export function fetchProductCategories(accessToken) {
  return fetch(
    accessToken,
    `${endpoint('products', 2)}${routes.products.categories}`,
    { includes: config.categoryFetchIncludes },
    'GET',
  )
}

export function fetchProducts(accessToken, cutoffDate, productsData, userId) {
  const data = {
    ...reqData,
    ...productsData,
    userId,
  }

  if (cutoffDate) {
    data.date = cutoffDate
  }

  return fetch(accessToken, `${endpoint('products', 2)}${routes.products.getProducts}`, data, 'GET')
}

export function fetchProductStock(accessToken) {
  return fetch(accessToken, `${endpoint('core')}${routes.core.productStock}`, {}, 'GET')
}
