import { useDispatch, useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { applyPromoCodeAndShowModal } from 'actions/home'
import { redirect } from 'actions/redirect'
import { trackUTMAndPromoCode } from 'actions/tracking'
import { clickClaimDiscountBar } from 'actions/trackingKeys'
import { useIsSkipTheWizardEnabled } from 'hooks/useIsSkipTheWizardEnabled'
import { getIsAuthenticated } from 'selectors/auth'
import { createGetErrorForActionType } from 'selectors/status'

export const useGetPromoBannerDispatchedActions = () => {
  const dispatch = useDispatch()
  const isSkipTheWizardEnabled = useIsSkipTheWizardEnabled()
  const dispatchApplyPromoCodeAndShowModal = () => dispatch(applyPromoCodeAndShowModal())
  const dispatchRedirect = () =>
    dispatch(redirect(`${isSkipTheWizardEnabled ? '/menu' : '/signup/box-size'}`))
  const dispatchTrackUTMAndPromoCode = (trackingEventName: string = clickClaimDiscountBar) =>
    dispatch(trackUTMAndPromoCode(trackingEventName))

  return {
    dispatchApplyPromoCodeAndShowModal,
    dispatchRedirect,
    dispatchTrackUTMAndPromoCode,
  }
}

export const useCanShowPromoBanner = () => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const promoCodeGetError = useSelector(createGetErrorForActionType(actionTypes.PROMO_GET))
  const promoCodeApplyError = useSelector(createGetErrorForActionType(actionTypes.PROMO_APPLY))

  const hasError = !!promoCodeGetError || !!promoCodeApplyError

  const hide = isAuthenticated || hasError

  return !hide
}
