import React from 'react'

import { Box, Button, Color, Display, Space, Visible } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { cta } from 'routes/BoxPrices/boxPricesConfig'
import { Benefits, BenefitType } from 'routes/Home/Benefits'

import {
  BoxComparisonTable,
  BoxComparisonTableHeader,
} from '../BoxPriceBlock/BoxPriceComparisonTable'
import { BoxComparisonHeaderType } from '../BoxPriceBlock/boxPriceBlockModels'
import { boxPricesBoxSizeSelected } from '../boxPricesActions'
import { BoxPricesWithAllPortionsType } from '../boxPricesModels'

type BoxPricesComparisonSectionType = Pick<
  BoxPricesWithAllPortionsType,
  'activeNumPortion' | 'activeRecipes' | 'boxInfo'
> & {
  selectedRecipeIndex: number
  isBestPrice: number
}

export const BoxPricesComparisonSection = ({
  selectedRecipeIndex,
  isBestPrice,
  activeNumPortion,
  activeRecipes,
  boxInfo,
}: BoxPricesComparisonSectionType) => {
  const hasBestPrice = isBestPrice === selectedRecipeIndex
  const dispatch = useDispatch()
  const boxPricesBoxSizeSelect = () => dispatch(boxPricesBoxSizeSelected(activeNumPortion))
  const moreRecipesHeader = `Select ${isBestPrice - selectedRecipeIndex} more recipe${
    selectedRecipeIndex < isBestPrice ? '' : 's'
  } for the best price`
  const bestPriceHeader = `You've got the best price per portion!`
  const { price_per_portion: pricePerPortion, total: pricePerBox } =
    boxInfo[activeNumPortion][activeRecipes]

  const CtaBlock = (
    <>
      <Button onClick={boxPricesBoxSizeSelect} color={Color.Secondary_600} width="100%">
        <Space size={10} />
        {cta}
      </Button>
      <Space size={2} />
      <Benefits pickFilter={[BenefitType.NO_LOCK_IN]} />
    </>
  )

  return (
    <Box>
      <Space size={5} />
      <BoxComparisonTableHeader
        type={
          hasBestPrice
            ? BoxComparisonHeaderType.BEST_PRICE
            : BoxComparisonHeaderType.SELECT_MORE_RECIPES
        }
        headerValue={hasBestPrice ? bestPriceHeader : moreRecipesHeader}
      />
      <BoxComparisonTable
        pricePerBox={pricePerBox || '--.--'}
        pricePerPortion={pricePerPortion || '--.--'}
        boxSize={activeRecipes + 2}
        numPortions={activeNumPortion}
      />

      <Space size={4} />

      <Visible display={Display.Block}>{CtaBlock}</Visible>

      <Space size={2} />

      <Box>
        <Benefits pickFilter={[BenefitType.PAID_DELIVERY]} />
      </Box>
    </Box>
  )
}
