import { connect } from 'react-redux'
import actions from 'actions'
import { trackUserAttributes } from 'actions/tracking'
import { getUserId } from 'selectors/user'
import { trackUserLogin } from 'actions/loggingmanager'
import { Page } from './Page'

export const PageContainer = connect((state) => ({
  isAuthenticated: state.auth.get('isAuthenticated'),
  disabled: state.auth.get('isAdmin'),
  email: state.user.get('email'),
  userId: getUserId(state),
  goustoReference: state.user.get('goustoReference'),
}), {
  loginVisibilityChange: actions.loginVisibilityChange,
  trackUserAttributes,
  trackUserLogin,
  userLoadData: actions.userLoadData,
})(Page)
