import moment from 'moment'

type CutOffDates = {
  cutOffFrom: string
  cutOffUntil: string
}

interface DateFormatter {
  (): {
    getFormattedCutOffDates(date: string | undefined): CutOffDates
    getFormattedMonthDayWithWeekday(date: string): string
    getShortMonthName(date: string): string
    getDayOfMonth(date: string): string
    getWeekDayName(date: string | undefined): string
    getFormattedMonthDayWithoutWeekday(date: string | undefined): string
  }
}

const momentDateFormatter: DateFormatter = () => {
  const getFormattedCutOffDates = (date: string | undefined): CutOffDates => {
    const cutOffFrom = moment(date).startOf('day').toISOString()
    const cutOffUntil = moment(date).startOf('day').add(21, 'days').toISOString()

    return { cutOffFrom, cutOffUntil }
  }

  const getFormattedMonthDayWithWeekday = (date: string): string =>
    moment(date).format('dddd Do MMM')

  const getShortMonthName = (date: string): string => moment(date).format('MMM')

  const getDayOfMonth = (date: string): string => moment(date).format('D')

  const getWeekDayName = (date: string | undefined): string => moment(date).format('dddd')

  const getFormattedMonthDayWithoutWeekday = (date: string | undefined): string =>
    moment(date).format('Do MMM')

  return {
    getFormattedCutOffDates,
    getFormattedMonthDayWithWeekday,
    getShortMonthName,
    getDayOfMonth,
    getWeekDayName,
    getFormattedMonthDayWithoutWeekday,
  }
}

export const dateFormatter = () => momentDateFormatter()
