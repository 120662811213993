import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  DisplayPosition,
  FlexDirection,
  Image,
  JustifyContent,
  Body2,
  FontWeight,
  TextAlign,
  useModal,
} from '@gousto-internal/citrus-react'

import { useCollections } from 'routes/Menu/domains/collections'

type PopularCategoryProps = {
  categoryName: string
  imageUrl: string
  recipeCount: number
  collectionId: string
}

export const PopularCategory = ({
  categoryName,
  imageUrl,
  recipeCount,
  collectionId,
}: PopularCategoryProps) => {
  const { changeCollectionById } = useCollections()
  const { closeCurrentModal } = useModal()

  const onClickCategory = () => {
    closeCurrentModal()
    changeCollectionById(collectionId)
  }

  return (
    <Box
      display={Display.Flex}
      justifyContent={JustifyContent.Center}
      paddingV={2}
      flexDirection={FlexDirection.Column}
      position={DisplayPosition.Relative}
      alignItems={AlignItems.Center}
      data-testid="popular-category"
      onClick={() => {
        onClickCategory()
      }}
      role="button"
      tabIndex={0}
      style={{ cursor: 'pointer' }}
      onKeyDown={(event) => {
        if (event.code === 'Space' || event.code === 'Enter') {
          event.preventDefault()
          onClickCategory()
        }
      }}
    >
      <Box bg={Color.NeutralGrey_50} width="100%" height="58px" />
      <Box position={DisplayPosition.Absolute} top="0">
        <Image src={imageUrl} alt={categoryName} height="71px" />
      </Box>
      <Box display={Display.Flex} paddingV={1} paddingBottom={0} alignItems={AlignItems.Center}>
        <Body2
          fontWeight={FontWeight.Bold}
          color={Color.ColdGrey_800}
          size={[2, null, 4]}
          textAlign={TextAlign.Center}
        >
          {categoryName}
        </Body2>
        <Body2 size={[2, null, 4]} color={Color.ColdGrey_500}>
          &nbsp;{recipeCount}
        </Body2>
      </Box>
    </Box>
  )
}
