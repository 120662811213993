import React from 'react'
import { Body1, Box, Color, FontFamily } from '@gousto-internal/citrus-react'

export function PromoBannerText({ children }: { children: string }) {
  return (
    <Box maxWidth={'100%'}>
      <Body1 color={Color.White} fontFamily={FontFamily.Bold}>
        {children}
      </Body1>
    </Box>
  )
}
