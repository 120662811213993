import { connect } from 'react-redux'

import { selectContactChannel } from 'actions/getHelp'
import { getUserId } from 'selectors/user'
import { getOrderId } from '../selectors/selectors'

import { Contact } from './Contact'

const mapStateToProps = (state) => ({
  content: {
    title: 'Contact us',
    body: 'Please get in touch so one of our customer care agents can help resolve your issue.',
    button1Copy: 'Done',
    chatItem: 'Start live chat',
    emailItem: 'Contact us by email',
    phoneItem: 'Contact us by phone',
  },
  orderId: getOrderId(state),
  userId: getUserId(state),
})

const ContactContainer = connect(mapStateToProps, {
  selectContactChannel,
})(Contact)

export {
  ContactContainer
}
