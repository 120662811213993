import React from 'react'

import { Box, Button } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { boxPricesBoxSizeSelectedDiscountVisibilityExperiment } from '../boxPricesActions'
import { discountInfographicCta } from '../boxPricesConfig'
import { BoxPricesWithAllPortionsType } from '../boxPricesModels'

type CtaType = Pick<BoxPricesWithAllPortionsType, 'activeNumPortion'>

export const Cta = ({ activeNumPortion }: CtaType) => {
  const dispatch = useDispatch()
  const boxPricesBoxSizeSelect = () =>
    dispatch(boxPricesBoxSizeSelectedDiscountVisibilityExperiment(activeNumPortion))

  return (
    <Box>
      <Button
        data-testid="build-my-box-with-discount-cta"
        width={['311px', '311px', '568px']}
        height={['48px', '56px']}
        onClick={boxPricesBoxSizeSelect}
      >
        {discountInfographicCta.text}
      </Button>
    </Box>
  )
}
