const firstBoxDiscountValue = 60
const secondBoxDiscountValue = 20

export const boxPrices: {
  [boxType: string]: { price: number; firstBoxDiscount: number; secondBoxDiscount: number }
} = {
  '2x1': {
    price: 18.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '3x1': {
    price: 23.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '4x1': {
    price: 28.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '5x1': {
    price: 33.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '2x2': {
    price: 25.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '3x2': {
    price: 30.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '4x2': {
    price: 35.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '5x2': {
    price: 43.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '2x3': {
    price: 30.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '3x3': {
    price: 40.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '4x3': {
    price: 49.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '5x3': {
    price: 58.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '2x4': {
    price: 32.75,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '3x4': {
    price: 43.75,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '4x4': {
    price: 50.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '5x4': {
    price: 59.8,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '2x5': {
    price: 42.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
  '3x5': {
    price: 54.99,
    firstBoxDiscount: firstBoxDiscountValue,
    secondBoxDiscount: secondBoxDiscountValue,
  },
}
