import React, { useState, useContext } from 'react'

import {
  AlignItems,
  Body1,
  Body2,
  Box,
  Color,
  Display,
  FontFamily,
  Icon,
  JustifyContent,
  Link,
  useModal,
} from '@gousto-internal/citrus-react'

import {
  ChangeFrequencyModal,
  SUBSCRIPTION_CHANGE_FREQUENCY_MODAL_NAME,
} from '../../../ActiveSubscription/sections/YourSubscriptionDetails/Frequency/ChangeFrequencyModal'
import { SubscriptionContext } from '../../../context'
import { actionTypes } from '../../../context/reducers'
import { getDeliveryFrequency } from '../../../context/selectors/deliveries'
import { frequencyMapping } from '../../../enum/frequency'
import { trackSubscriptionSettingsChange } from '../../../tracking'
import { FrequencyKey } from '../../types'

export const FrequencySection = () => {
  const { state, dispatch } = useContext<any>(SubscriptionContext)
  const { openModal } = useModal()
  const currentDeliveryFrequency = getDeliveryFrequency(state)
  const [boxFrequency, setBoxFrequency] = useState<FrequencyKey>(currentDeliveryFrequency)

  const onFrequencyClick = (event: React.SyntheticEvent) => {
    event.preventDefault()
    openModal(SUBSCRIPTION_CHANGE_FREQUENCY_MODAL_NAME)
    trackSubscriptionSettingsChange({ settingName: 'reactivate_box_frequency', action: 'edit' })({
      previous_frequency: boxFrequency,
    })
  }

  const handleFrequencySubmit = (selectedFrequency: string) => {
    setBoxFrequency(Number(selectedFrequency) as FrequencyKey)
    dispatch({
      type: actionTypes.UPDATE_SELECTED_FREQUENCY,
      data: { currentValue: Number(selectedFrequency) },
    })
  }

  return (
    <>
      <ChangeFrequencyModal
        isModalOpen={false}
        currentDeliveryFrequency={String(boxFrequency)}
        onFrequencySubmit={handleFrequencySubmit}
      />
      <Box display={Display.Flex} justifyContent={JustifyContent.SpaceBetween}>
        <Box width={['2.5rem']}>
          <Icon name="frequency" />
        </Box>
        <Box width={['100%']}>
          <Body1 fontFamily={FontFamily.Bold} color={Color.ColdGrey_800}>
            Frequency
          </Body1>
          <Body2>{frequencyMapping[boxFrequency]}</Body2>
        </Box>
        <Box
          width={['3rem']}
          display={Display.Flex}
          alignItems={AlignItems.Baseline}
          justifyContent={JustifyContent.FlexEnd}
        >
          <Link href="#frequency" onClick={onFrequencyClick} data-testid="frequencyEditButton">
            Edit
          </Link>
        </Box>
      </Box>
    </>
  )
}
