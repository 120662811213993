import React from 'react'

import { Checkbox, Text } from '@gousto-internal/citrus-react'

import { FieldContainer } from 'routes/Checkout/Components/AboutYou/FieldContainer'

type StayUpToDateCheckboxFieldProps = {
  id: string
  checked: boolean
  onChange: () => void
}

export const StayUpToDateCheckboxField = ({
  id,
  checked,
  onChange,
}: StayUpToDateCheckboxFieldProps) => (
  <FieldContainer>
    <Checkbox
      id={id}
      data-testing="checkoutAllowEmailCheckbox"
      checked={checked}
      onChange={onChange}
    >
      <Text size={1}>
        {'I’d like to stay up to date with the latest recipes, and get '}
        exclusive offers on my Gousto orders via email, SMS or post.
      </Text>
    </Checkbox>
  </FieldContainer>
)
